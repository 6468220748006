import { AnimationEvent, AnimationTriggerMetadata, animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

const translateInOut: AnimationTriggerMetadata = trigger('transition', [
  state('*', style({ transform: 'translateY(0)' })),
  state('void', style({ transform: 'translateY(0)' })),

  state('bottomOut', style({ transform: 'translateY(100%)' })),
  state('topOut', style({ transform: 'translateY(-100%)' })),

  transition('void => topIn', [
    style({ transform: 'translateY(-100%)' }),
    animate('1000ms ease-in-out')
  ]),

  transition('void => bottomIn', [
    style({ transform: 'translateY(100%)' }),
    animate('1000ms ease-in-out')
  ]),

  transition('* => *', animate('1000ms ease-out'))
]);

@Component({
  selector: 'cookie-law',
  styleUrls: ['./cookie-law.component.scss'],
  templateUrl: './cookie-law.component.pug',
  animations: [translateInOut],
  encapsulation: ViewEncapsulation.None
})
export class CookieLaw implements OnInit {
  @Input() public name: string;
  @Input() public learnMore: string;
  @Input() public expiration: number;
  @Input() public target: '_blank' | '_self';
  public currentStyles: any;

  public seen: boolean;
  public transition: string;
  private position: string

  constructor(private cookieService: CookieService) {
    this.name = 'cookieLawSeen';
    this.seen = true;
    this.transition = 'bottomIn';
    this.position = 'bottom';
    this.target = '_self';
  }

  public ngOnInit(): void {
    this.seen = this.cookieService.check(this.name);
    this.transition = this.position === 'bottom' ? 'bottomIn' : 'topIn';

    this.currentStyles = {
      top: this.position === 'top' ? '0' : null,
      bottom: this.position === 'top' ? 'initial' : null
    };
  }

  public dismiss(evt?: MouseEvent): void {
    if (evt) {
      evt.preventDefault();
    }
    this.transition = this.position === 'top' ? 'topOut' : 'bottomOut';
  }

  public afterDismissAnimation(evt: AnimationEvent): void {
    if (evt.toState === 'topOut' || evt.toState === 'bottomOut') {
      this.cookieService.set(this.name, 'true', this.expiration, '/');
      this.seen = true;
    }
  }

}
