import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AccountService } from '../../../../services/account.service';

@Component({
  selector: 'forgot-password-modal',
  styleUrls: ['./forgot-password-modal.component.scss'],
  templateUrl: './forgot-password-modal.component.pug'
})
export class ForgotPasswordModal {
  public showSuccessAlert: boolean;
  public showErrorAlert: boolean;

  constructor(private accountService: AccountService, public dialog: BsModalRef) {
  }

  public onKeyUp(value: any) {
    this.dialog.hide();
  }

  public onRecoverButtonClicked(email: string) {
    this.showErrorAlert = false;
    this.accountService.requestResetPassword(email).subscribe(
      success => {
        this.showSuccessAlert = true;
      },
      error => {
        this.showErrorAlert = true;
      }
    );
  }
}
