import { Component, Input, ViewChild } from '@angular/core';

import { CheckboxInput } from '../../form-controls';

@Component({
  selector: 'terms-conditions-form',
  styleUrls: ['./terms-conditions-form.component.scss'],
  templateUrl: './terms-conditions-form.component.pug'
})
export class TermsConditionsForm {
  @Input() public showHighlightedError: boolean;
  @ViewChild(CheckboxInput, { static: true }) public checkbox: CheckboxInput;
  public today: Date = new Date();

  public valid() {
    return this.value();
  }

  public value() {
    return this.checkbox.value;
  }
}
