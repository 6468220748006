import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HTTPMethod, ISuccessResponse, NetworkService } from './network.service';

import { Question, QuestionIncludeType, Registration, RegistrationData, ScreeningExpanded } from '../../../common/interfaces';

@Injectable({
  providedIn: 'root',
})
class ScreeningService {

  constructor(private networkService: NetworkService) { }

  public validate(screeningPassword: string, recruiterCode: string, referrer: string): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.POST, 'Screenings/validation',
      { moviePassword: screeningPassword, recruiterCode, referrer });
  }

  public getById(screeningId: number, questionParams?: QuestionIncludeType): Observable<ScreeningExpanded> {
    return this.networkService.httpRequest(HTTPMethod.GET, `Screenings/${screeningId}`, questionParams);
  }

  public register(screeningId: number, registrationData: RegistrationData, account?: any): Observable<Registration> {
    return this.networkService.httpRequest(HTTPMethod.POST, `Screenings/${screeningId}/register`, { registrationData, account });
  }
  // tslint:disable-next-line
  public forwardInvites(screeningId: number, addresses: string[], fullName: string, invitationId: any, recruiterCode?: string): Observable<ISuccessResponse> {
    return this.networkService.httpRequest(
      HTTPMethod.POST, `Screenings/${screeningId}/forwardEmails`, { addresses, recruiterCode, fullName, invitationId });
  }

  public getRegistrationQuestions(screeningId: number): Observable<Question[]> {
    return this.networkService.httpRequest<Question[]>(HTTPMethod.GET, `Screenings/${screeningId}/getRegistrationQuestions`);
  }

  public sendConfirmationEmail(registration: any) {
    return this.networkService.httpRequest(
      HTTPMethod.POST, `Screenings/sendConfirmationEmail`, { registration });
  }

  public sendDigitalConfirmationEmail(registration: any, guestOnly: boolean) {
    return this.networkService.httpRequest(
      HTTPMethod.POST, `Screenings/sendDigitalConfirmationEmail`, { registration, guestOnly });
  }
  // Screenings/4548/resendConfirmations
  public resendConfirmations(
    id: number,
    registrationIds: number[]
  ) {
    return this.networkService.httpRequest(
      HTTPMethod.POST, `Screenings/${id}/resendConfirmations`, { registrationIds });
  }
}

export { ScreeningService };

