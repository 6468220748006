import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
// tslint:disable
import { AccountExpanded, RegistrationExpanded } from '../../../../../../common/interfaces';
import { AccountService, ScreeningService, SharedService } from '../../../../services';

import { distinctUntilChanged } from 'rxjs/operators';

declare const ga: Function;

@Component({
  selector: 'home-page',
  styleUrls: ['./home-page.component.scss'],
  templateUrl: './home-page.component.pug'
})
export class HomePage implements OnInit {
  private account: AccountExpanded;
  public showConfirmationError: boolean;
  public confirmationError: string;
  public showFullError: boolean;
  public referrer: string;

  constructor(private router: Router, private titleService: Title, private screeningService: ScreeningService,
    private sharedService: SharedService, private accountService: AccountService) {
    router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    })).subscribe((navigation: any) => {
      ga('set', 'page', navigation.url);
      ga('send', 'pageview');
    })
  };

  public ngOnInit() {
    this.titleService.setTitle('Preview Free Movies');
    this.accountService.currentUser.subscribe(
      currentUser => this.account = currentUser
    );

    // clear anonymous access token
    // let roles = JSON.parse(this.cookieService.get('roles'));
    // if ((roles && roles.length > 0 && roles[0].name === 'Anonymous') || !roles) {
    //   this.networkService.clearAuthInfo();
    // }
  }

  public onConfirmClicked(values: any) {
    this.referrer = this.parseURL(document.referrer);
    this.screeningService.validate(values.password, values.recruiterCode, this.referrer).subscribe(
      res => {

        let data: RegistrationExpanded = {
          screeningId: res.Screening.id,
          Screening: res.Screening,
          recruiterId: res.recruiterId
        };
        this.sharedService.setScreeningRegistration(data);
        this.sharedService.setRecruiterCode(values.recruiterCode);
        this.router.navigate(['/screenings', res.Screening.id, 'register', res.recruiterId]);
      },
      error => {
        if (!error.message) {
          error.message = 'Could not confirm. Please check your code and try again.';
        }
        if (error.status === 403) {
          this.sharedService.setRegistrationError(error);
          this.router.navigate(['registration-error']);
        } else {
          this.confirmationError = error.message;
          this.showConfirmationError = true;
        }
      }
    );
  }

  private parseURL(href: string) {
    // tslint:disable-next-line
    let ip = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (href != null && href.length > 0) {
      if (ip.test(href)) {
        return href;
      } else {
        try {
          let parser = document.createElement('a');
          parser.href = href;
          return parser.hostname;
        } catch (e) {
          return href;
        }
      }
    }
    return href;
  }
}
