import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import { CookieService } from 'ngx-cookie-service';

import { filter } from 'rxjs/operators';
import '../styles.scss';

import { AccountService } from '../services';

@Component({
  selector: 'pfm',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private accountService: AccountService, private router: Router) { };
  // @ViewChild('cookieLaw', { static: false }) private cookieLawEl: any; //for extra methods

  public ngOnInit() {
    this.accountService.verifyCurrentUser();

    // https://github.com/angular/angular/issues/7791
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      window.scroll(0, 0);
    });
  }

  public accept() {
    // this.cookieService.set('gaOptOut', 'false', 365, '/')
    // const gaCreateTracker = window['gaCreateTracker']
    // gaCreateTracker();
    // this.cookieLawEl.dismiss();
  }
}
