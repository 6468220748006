import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RegistrationService } from '../../../../services';

@Component({
  selector: 'waitlist-modal',
  styleUrls: ['./waitlist-modal.component.scss'],
  templateUrl: './waitlist-modal.component.pug'
})
export class WaitlistModal {
  public removedFromWaitlist: boolean;
  public respondedToQuestion: boolean;
  public isFull: boolean;
  public registrationId: number;
  public screening: any;

  constructor(private registrationService: RegistrationService, public dialog: BsModalRef) {
    this.isFull = this.screening.waitlistCount > this.screening.waitlistMax ? true : false;
  }

  public onKeyUp() {
    this.close();
  }
  public onOKClicked() {
    this.close();
  }

  public onYesClicked() {
    this.registrationService.addToWaitlist(this.registrationId).subscribe(
      (registration) => {
        this.respondedToQuestion = true;
      },
      (err) => {
        console.log('Add to waitlist err - ', err);
        this.close();
      }
    );
  }

  public onNoClicked() {
    this.close();
  }

  public onOkClicked() {
    this.close();
  }

  public onRemoveFromWaitlistClicked() {
    this.registrationService.cancel(this.registrationId).subscribe(
      (registration) => {
        this.removedFromWaitlist = true;
      },
      (err) => {
        console.log('Update registration err - ', err);
        this.close();
      }
    );
  }

  private close() {
    this.dialog.hide();
  }

}
