import { Injectable } from '@angular/core';

import { KidExpanded, RegistrationError, ScreeningExpanded } from '../../../common/interfaces';
import { RegistrationData, RegistrationExpanded } from '../../../common/interfaces/registration';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
class SharedService {
  public registrationError: RegistrationError = {};
  private _screeningRegistration: RegistrationExpanded = {};
  private _registrationConfirmationObj: any = {}
  private _accountRegistration: RegistrationData = {};
  private _recruiterId?: number;
  private _recruiterCode?: Number;
  private _kids?: any[] = []
  private _isEditing?: boolean = false;

  constructor(private accountService: AccountService) {
    this.accountService.currentUser.subscribe(
      currentUser => {
        this._screeningRegistration.Account = currentUser;
      }
    );
  }

  public setIsEditing(isEditing: boolean) {
    this._isEditing = isEditing;
  }

  public getIsEditing() {
    return this._isEditing
  }

  public getKids() {
    return this._kids
  }

  public setKids(kids: KidExpanded[]) {
    this._kids = kids;
  }

  public getRecruiterId() {
    return this._recruiterId;
  }

  public setRecruiterId(recruiterId: number) {
    this._recruiterId = recruiterId;
  }

  public getRecruiterCode() {
    return this._recruiterCode;
  }

  public setRecruiterCode(recruiterCode: Number) {
    this._recruiterCode = recruiterCode;
  }

  public setRegistrationConfirmationObj(registrationConfirmationObj: any) {
    this._registrationConfirmationObj = registrationConfirmationObj;
  }

  public getRegistrationConfirmationObj() {
    return this._registrationConfirmationObj;
  }

  public setScreeningRegistration(screeningRegistration: RegistrationExpanded) {
    this._screeningRegistration = screeningRegistration;
  }

  public getScreeningRegistration() {
    return this._screeningRegistration;
  }

  public setAccountRegistration(accountRegistration: RegistrationData) {
    this._accountRegistration = accountRegistration;
  }

  public getAccountRegistration() {
    return this._accountRegistration;
  }

  public getRegistrationError(): RegistrationError {
    return this.registrationError;
  }

  public setRegistrationError(data: RegistrationError): void {
    this.registrationError = data;
    return;
  }

  public getScreeningAddress(screening: ScreeningExpanded): string {
    if (!screening || !screening.Venue) {
      return '';
    }
    return `${screening.Venue.addressLine1},
              ${screening.Venue.addressLine2 ? screening.Venue.addressLine2 + ', ' : ''}
              ${screening.Venue.city ? screening.Venue.city + ', ' : ''}
              ${screening.Venue.region ? screening.Venue.region + ', ' : ''}
              ${screening.Venue.postalCode ? screening.Venue.postalCode : ''}`;
  }

  public getScreeningLocation(screening: ScreeningExpanded): string {
    if (!screening || !screening.Venue) {
      return '';
    }
    return `${screening.Venue.city ? screening.Venue.city + ', ' : ''}
              ${screening.Venue.region ? screening.Venue.region : ''}`;
  }
}

export { SharedService };

