import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HTTPMethod, NetworkService } from './network.service';

import { Country } from '../../../common/interfaces';

@Injectable()
class CountryService {

    constructor(private networkService: NetworkService) { }

    public get(): Observable<Array<Country>> {
        return this.networkService.httpRequest(HTTPMethod.GET, `Countries/`);
    }
}

export { CountryService };
