import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { AccountService, RegistrationService, ScreeningService, SharedService } from '../../../../services';

import { RegistrationExpanded, ScreeningExpanded } from '../../../../../../common/interfaces';


@Component({
  selector: 'pre-check-nda-page',
  styleUrls: ['./pre-check-nda-page.component.scss'],
  templateUrl: './pre-check-nda-page.component.pug'
})
export class PreCheckNDAPage implements OnInit {

  public screeningAddress: string = '';
  public screeningLocation: string = '';
  public isOnline: boolean;
  public registration: RegistrationExpanded;
  public screening: ScreeningExpanded;
  private encryptedId: string;
  private screeningId: number;
  private accessTokenId: string;
  private firstEl: HTMLInputElement;
  public loading: boolean = true;
  public showError: any = null;
  public success: any = null;
  public verifyCodeForm: FormGroup;
  public waiting: boolean = false;
  public submitted: boolean = false;

  constructor(public screeningService: ScreeningService, private registrationService: RegistrationService, private route: ActivatedRoute,
    private router: Router, private sharedService: SharedService, private titleService: Title, public accountService: AccountService, private cookieService: CookieService) { }

  public ngOnInit() {
    this.titleService.setTitle('Pre-Check In - NDA Sent');
    this.verifyCodeForm = new FormGroup(Array.from(Array(6)).reduce((p, c, i) => (p[`${i}`] = new FormControl(null, Validators.required), p), {}))
    let id = this.route.snapshot.params['id'];
    let screeningId = this.route.snapshot.params['screeningId'];
    this.encryptedId = id;
    const TOKEN_KEY = 'access_token';
    let accessTokenId = this.route.snapshot.queryParams[TOKEN_KEY];
    if (accessTokenId) {
      this.accessTokenId = accessTokenId;
    } else {
      this.accessTokenId = this.cookieService.get('accessToken');
    }
    this.registrationService.getByIdCheckIn(id, screeningId).subscribe(data => {
      this.registration = data;
      this.screening = data.Screening;
      if (data.ScreeningInvitation) {
        this.screening.title = data.ScreeningInvitation.isBlind ? 'Movie Preview' : data.ScreeningInvitation.title
      }
      this.setScreeningData();
    }, err => {
      console.log(err)
      if (err.status === 404) this.router.navigate(['/not-found']);
    }, () => {
      if (!this.registration.ndaSent) {
        this.router.navigate(['/screenings', this.encryptedId, 'pre-check']);
      } else {
        this.loading = false;
      }
    })
  }

  public ngAfterViewChecked() {
    if (!(this.loading || !!this.firstEl)) {
      const els = Array.from(document.getElementsByClassName('verify'))
      const subEl = document.getElementById('submit')
      els.forEach((el, i) => {
        if (!i) {
          el.addEventListener("paste", (ev: Event) => {
            const event = ev as ClipboardEvent
            event.preventDefault();
            const paste = event.clipboardData.getData("text");
            this.verifyCodeForm.setValue(paste.substring(0, 6).split('').reduce((p, c, ix) => (p[ix] = c.replace(/[^0-9]/g, ''), p), {}))
          });
        }
        el.addEventListener("keypress", (ev: Event) => {
          const event = ev as KeyboardEvent
          if (!(/^[0-9]$/.test(event.key) || event.key === "Backspace" || event.key === "Delete")) {
            event.preventDefault();
          }
        })
        el.addEventListener("keyup", (ev: Event) => {
          const event = ev as KeyboardEvent
          const target = event.target as HTMLInputElement
          if (event.key === 'v' && event.ctrlKey) {
            if (subEl) subEl.focus()
          } else if (!!target.value && /^[0-9]$/.test(event.key)) {
            const next = target.nextElementSibling as HTMLInputElement
            if (next) {
              next.focus()
              next.select()
            } else {
              if (subEl) subEl.focus()
            }
          }
        });
      })
      this.firstEl = els[0] as HTMLInputElement
      this.firstEl.focus()
    }
  }

  private setScreeningData() {
    this.sharedService.setScreeningRegistration(this.registration);
    this.titleService.setTitle('Pre-Check In - ' + this.screening.title + ' - NDA Sent');
    this.screeningAddress = this.sharedService.getScreeningAddress(this.screening);
    this.screeningLocation = this.sharedService.getScreeningLocation(this.screening);
    this.isOnline = this.screening.Venue && this.screening.Venue.isOnline
  }

  public submit(values: any) {
    this.submitted = true;
    this.success = null;
    this.showError = null;
    const code = Object.keys(values).reduce((p, c) => (p[c] = values[c], p), []).join('')
    this.registrationService.verifyNDA(this.registration.id, code, this.accessTokenId).subscribe(
      (resp: any) => {
        if (resp.ndaId) {
          this.router.navigate(['/nda', resp.ndaId]);
        } else {
          this.showError = 'An error has occurred. Please try again.'
          this.submitted = false;
        }
      },
      (err) => {
        this.showError = 'An error has occurred. Please try again.'
        this.submitted = false;
        console.log('Error', err)
      }
    );
  }

  public resend() {
    this.waiting = true;
    this.success = null;
    this.showError = null;
    this.verifyCodeForm.reset();
    if (this.firstEl) this.firstEl.focus()
    this.registrationService.sendNDA(this.encryptedId, this.screening.id, this.accessTokenId).subscribe((resp) => {
      if (resp.ndaSent) {
        this.success = 'Your NDA has been resent'
        this.waiting = false;
      } else {
        this.showError = 'An error has occurred. Please try again.'
        this.waiting = false;
      }
    },
      (err) => {
        this.showError = 'An error has occurred. Please try again.'
        this.waiting = false;
        console.log('Error', err)
      });
  }
}
