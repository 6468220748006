import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { RadioInput } from '../../form-controls';

@Component({
  selector: 'online-venue-form',
  styleUrls: ['./online-venue-form.component.scss'],
  templateUrl: './online-venue-form.component.pug'
})
export class OnlineVenueForm {
  @Input() public showHighlightedError: boolean;
  @ViewChildren(RadioInput) public radios: QueryList<RadioInput>;

  public questions: any[] = [
    {
      questionText: 'Are you willing to be on camera and be recorded for our business purposes?',
      questionGroup: 'Allow_Recording',
      questionOptions: [
        {
          label: 'YES',
          answer: 'VALID',
        },
        {
          label: 'NO',
          answer: 'INVALID',
        }
      ]
    },
    {
      questionText: 'Do you own or have access to a desktop or laptop computer equipped with a working camera that support video calls/meetings (i.e. Zoom)? <i>Please note that iPads, Chromebooks, or other tablets are NOT permitted for this study</i>',
      questionGroup: 'Camera_Tech',
      questionOptions: [
        {
          label: 'YES',
          answer: 'VALID',
        },
        {
          label: 'NO',
          answer: 'INVALID',
        }
      ]
    }
  ]

  public value: any = {
    'Allow_Recording': null,
    'Camera_Tech': null
  }

  public values() {
    return this.value;
  }

  public isInvalid() {
    return Object.values(this.value).includes('INVALID')
  }

  public isEmpty() {
    return Object.values(this.value).includes(null)
  }

  public onItemChecked(item: any) {
    this.value[item.option] = item.answer;
  }

}
