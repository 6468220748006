import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { SharedService } from '../../../services/shared.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class ScreeningRegistrationGuard implements CanActivate {
  constructor(private sharedService: SharedService, private router: Router, private cookieService: CookieService) { }

  // only allow admins or users with a recruiterId in sharedService to view screening registration
  public canActivate(route: ActivatedRouteSnapshot): boolean {
    let isAdmin = false;
    let roles = this.cookieService.get('roles');
    if (roles) {
      let arr: Array<string> = JSON.parse(roles);
      arr.forEach((role: any) => {
        if (['Administrator', 'SuperAdmin', 'Manager', 'Limited'].includes(role.name)) {
          isAdmin = true;
          return;
        }
      });
    }

    // tslint:disable
    let allowed = isAdmin || !!this.sharedService.getScreeningRegistration().recruiterId
      || route.data['isInvitationLink'] === true;
    if (!allowed) {
      this.router.navigate(['']);
    }
    return allowed;
  }

}
