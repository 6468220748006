import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { CookieLaw } from './cookie-law.component';

@NgModule({
    imports: [CommonModule],
    declarations: [CookieLaw],
    exports: [CookieLaw, CommonModule]
})
export class CookieLawModule {
    constructor(@Optional() @SkipSelf() parentModule: CookieLawModule) {
        if (parentModule) {
            throw new Error(
                'CookieLawModule is already loaded. Import it in the AppModule only'
            );
        }
    }
}
