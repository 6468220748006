import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'datepicker-field',
  styleUrls: ['./datepicker-field.component.scss'],
  templateUrl: './datepicker-field.component.pug',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatepickerField),
    multi: true
  }]
})
export class DatepickerField implements ControlValueAccessor {
  @Input() public labelText: string;
  @Input() public pastOnly: boolean;
  @Input() public futureOnly: boolean;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;

  public showCalendar: boolean;
  public value: Date;
  public propagateChange = (_: any) => {
    // Default noop until registerOnChange is called
  };

  public ngOnInit() {
    if (this.maxDate === undefined && this.pastOnly) {
      this.maxDate = new Date();
    }
    if (this.minDate === undefined && this.futureOnly) {
      this.minDate = new Date();
    }
  }

  public onDatePicked(date: Date) {
    this.value = date;
    this.propagateChange(this.value);
    this.showCalendar = false;
  }

  public writeValue(value: any) {
    if (value !== undefined && value !== this.value) {
      this.value = value;
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
    // Do nothing, don't care about onTouch events
  }
}
