import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';
import 'moment-timezone';
/*
 * Time helper using momentjs
 * Usage:
 *   timestamp | moment:'DD.MM.YYYY'
 * Defaults to 'L' - locale ie. '01/24/2016'
*/
@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
  public transform(value: Date | number, args: string, subtract?: any, timezone?: string): any {
    timezone = timezone || 'UTC';
    let date = moment(value);

    if (typeof subtract === 'string') {
      timezone = subtract;
      subtract = null;
    }

    if (!date.isValid()) { return value; }

    date = moment.tz(value, timezone);
    if (subtract) {
      date = date.subtract(subtract.amount, subtract.unit);
    }
    return date.format(args || 'L');
  }
}
