import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HTTPMethod, NetworkService } from './network.service';

import { ScreeningInvitation, ScreeningInvitationRendered } from '../../../common/interfaces';

@Injectable()
class ScreeningInvitationService {
  constructor(private networkService: NetworkService) { }

  public getById(id: string, encrypted: boolean): Observable<ScreeningInvitation> {
    return this.networkService.httpRequest(HTTPMethod.GET, `ScreeningInvitations/${id}`, { encrypted });
  }

  public generateTemplate(id: string, toggleLanguage: boolean, logId?: number): Observable<ScreeningInvitationRendered> {
     return this.networkService.httpRequest(HTTPMethod.POST, `ScreeningInvitations/${id}/generateTemplate`,
      { toggleLanguage, logId });
  }
}

export { ScreeningInvitationService };
