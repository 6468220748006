import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AccountService } from '../../../services/account.service';

@Injectable()
export class LoggedOutGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) { }

  public canActivate(): Observable<boolean> {
    return this.accountService.currentUser.asObservable().pipe(
      map(account => {
        if (account) {
          this.router.navigate(['']);
        }
        return !account;
      }),
      take(1)
    );
  }
}
