// tslint:disable
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../../services';
// tslint:enable

@Component({
    selector: 'registration-error-page',
    styleUrls: ['./registration-error-page.component.scss'],
    templateUrl: './registration-error-page.component.pug'
})
export class RegistrationErrorPage implements OnInit, OnDestroy {
    public error: any;

    constructor(private titleService: Title, private sharedService: SharedService) { }

    public ngOnInit() {
        // tslint:disable-next-line
        this.titleService.setTitle('Registration Error');
        let error = this.sharedService.getRegistrationError();
        this.error = {
            message: error.message,
            title: error.errorTitle
        };
    }

    public ngOnDestroy() {
        this.sharedService.setRegistrationError({});
    }
}
