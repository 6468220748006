import { Answer } from './answer';
import { BaseModel } from './base';

enum QuestionTemplateId {
  Age = 1, Ethnicity, Gender, FieldsOfEmployment, Education, Ideology, Income, MaritalStatus,
  MovieInfluence, AvoidedGenres, MovieType, Political, PriorMovieList, PriorMovieList2,
  PriorMovieListEnjoyed, Profession, Religion, ReligiousAttendance, SexualPreference, ChildAge,
  ChildGender = 43, ChildEthnicity = 41
}

enum QuestionTemplateRegistrationFilter {
  None = 0, Required = 1, Optional = 2
}

interface QuestionTemplate extends BaseModel {
  label?: string;
  displayQuestion?: string;
  displayOrder?: number;
  fieldCode?: string;
  isForRegistration?: boolean;
  isRegistrationOptional?: boolean;
  isDefault?: boolean;
  isEditable?: boolean;
  isLegacy?: boolean;
  configJSON?: any;
  isDeleted?: boolean;
  Answers?: Array<Answer>;
  answer?: any;
  formControlName?: any;
}

enum QuestionDataType {
   List = 358, CheckGroup = 408, Grid = 808, Text = 1
}

export { QuestionTemplate, QuestionDataType, QuestionTemplateId, QuestionTemplateRegistrationFilter }
