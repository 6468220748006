import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { AccountExpanded, AccountRoleType } from '../../../../../common/interfaces/account';
import { AccountService } from '../../../services/account.service';
import { NetworkService } from '../../../services/network.service';
import { LoginModal } from '../modals/login-modal';

@Component({
  selector: 'pfm-header',
  styleUrls: ['./pfm-header.component.scss'],
  templateUrl: './pfm-header.component.pug'
})
export class PfmHeader implements OnInit {
  @Input() public account: AccountExpanded;
  @Input() public shouldRedirect = true; // false when logging in during screening registrations
  @Input() public isHomePage: boolean;

  public displayNav: boolean = false;
  public displayAdminCMS: boolean = false;
  public oktaVerified: boolean = false;
  public displayRecruiterCMS: boolean = false;

  constructor(private modalService: BsModalService, private networkService: NetworkService,
    private router: Router, private accountService: AccountService, private route: ActivatedRoute, private cookieService: CookieService) {

  }

  public ngOnInit() {
    // this.route.fragment.subscribe((fragment) => {
    //   const oktaSession = new URLSearchParams(fragment);
    //   const token = oktaSession.get('id_token');

    //   if (token) {
    //     this.cookieService.set('okta_token', token, 0.375); // expire in 9 hours
    //     const landingPage = '/cms/dashboard/screenings/Navigation/screening-list';
    //     window.location.href = landingPage;
    //   }
    // });

    this.oktaVerified = !!(this.cookieService.get('okta_token'));


    this.accountService.currentUser.subscribe(
      currentUser => {
        if (currentUser) {
          if (currentUser.RoleMapping) {
            const roleId = currentUser.RoleMapping.roleId;
            const isAdmin = [AccountRoleType.SuperAdmin, AccountRoleType.Administrator, AccountRoleType.Manager, AccountRoleType.Limited].includes(roleId);
            if (!isAdmin) {
              this.oktaVerified = true;
            }
          }
        }
        // When okta is enabled
        if (currentUser && currentUser.emailVerified && this.oktaVerified) {
          // only verified users are considered 'logged in'
          this.account = currentUser;
          if (currentUser.RoleMapping) {
            this.displayAdminCMS = [AccountRoleType.Administrator, AccountRoleType.Manager, AccountRoleType.Limited].includes(currentUser.RoleMapping.roleId);
            this.displayRecruiterCMS = currentUser.RoleMapping.roleId === AccountRoleType.Recruiter;
          }
        }
      }, err => {
        console.log('verifying error', err)
        this.oktaVerified = false
      }
    );
  }

  public ngOnDestroy() {
    this.oktaVerified = false;
  }


  public onLoginClicked() {
    if (!this.account) {
      // this.cookieService.deleteAll();
      this.networkService.clearAuthInfo();
      this.oktaVerified = false;
      this.presentLogInModal();
    } else {
      this.account = null;
      this.oktaVerified = false;
      this.accountService.logout().subscribe((res) => {
        this.accountService.clearUser();
        this.router.navigate(['']);
        console.log(res);
      }, (err) => {
        this.accountService.clearUser();
        this.router.navigate(['']);
        console.log(err);
      // }, () => {
      //   this.accountService.clearUser();
      //   this.router.navigate(['']);
      });
    }
  }

  public onMenuIconClicked() {
    this.displayNav = !this.displayNav;
  }

  public presentLogInModal() {
    this.modalService.show(LoginModal, {
      initialState: { shouldRedirect: this.shouldRedirect }
    });
  }
}
