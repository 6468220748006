import { RouterModule, Routes } from '@angular/router';

import { CookiesPolicyPage, ConfirmAttendancePage, EmailConfirmationPage, FAQPage, HomePage, NdaPage, NdaSignPage, NdaCompletePage, NotFoundPage, PreCheckConfirmationPage, PrivacyPolicyPage, RegistrationErrorPage, RegistrationOptionalPage,
  RegistrationPage, ResetPasswordPage, ScreeningConfirmationPage, ScreeningInvitationPage, TermsConditionsPage,
  DoNotSellPage, UpdateProfilePage, UserScreeningsPage, VerificationPage, PreCheckNDAPage, EmailVerificationFailedPage } from './components/pages';

import { NgModule } from '@angular/core';
import { LoggedInGuard } from './common/guards/logged-in.guard';
import { LoggedOutGuard } from './common/guards/logged-out.guard';
import { PendingChangesGuard } from './common/guards/pending-changes.guard';
import { PrivateInviteGuard } from './common/guards/private-invite.guard';
import { ScreeningRegistrationGuard } from './common/guards/screening-registration.guard';

const appRoutes: Routes = [
  // {
  //   path: '',
  //   component: SiteMaintenancePage
  // },
  {
    path: '',
    component: HomePage
  },
  {
    path: 'register',
    component: RegistrationPage
  },
  {
    path: 'register-external',
    component: RegistrationPage
  },
  {
    path: 'register/:recruiterId',
    component: RegistrationPage
  },
  {
    path: 'registration/step-two',
    component: RegistrationOptionalPage
  },
  {
    path: 'registration/confirmation',
    component: EmailConfirmationPage
  },
  {
    path: 'verification-failed',
    component: EmailVerificationFailedPage
  },
  {
    path: 'screenings/:id/register/:recruiterId',
    component: ConfirmAttendancePage,
    canActivate: [ScreeningRegistrationGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'invite/:invitationId/confirm/:screeningNumber',
    component: ConfirmAttendancePage,
    canActivate: [PrivateInviteGuard, ScreeningRegistrationGuard],
    canDeactivate: [PendingChangesGuard],
    data: { isInvitationLink: true }
  },
  {
    path: 'invite/:invitationId/confirm/:screeningNumber/:registrationId',
    component: ConfirmAttendancePage,
    canActivate: [PrivateInviteGuard, ScreeningRegistrationGuard],
    canDeactivate: [PendingChangesGuard],
    data: { isInvitationLink: true, hasRegistration: true }
  },
  {
    path: 'invite-external/:invitationId/confirm/:screeningNumber',
    component: ConfirmAttendancePage,
    canActivate: [PrivateInviteGuard, ScreeningRegistrationGuard],
    canDeactivate: [PendingChangesGuard],
    data: { isInvitationLink: true }
  },
  {
    path: 'invite-external/:invitationId/confirm/:screeningNumber/:registrationId',
    component: ConfirmAttendancePage,
    canActivate: [PrivateInviteGuard, ScreeningRegistrationGuard],
    canDeactivate: [PendingChangesGuard],
    data: { isInvitationLink: true, hasRegistration: true }
  },
  {
    path: 'screenings/:id/confirmation/:registrationId/update',
    component: ConfirmAttendancePage,
    data: { isEditing: true }
  },
  {
    path: 'screenings/:id/confirmation/:registrationId',
    component: ScreeningConfirmationPage
  },
  {
    path: 'invite/:id',
    component: ScreeningInvitationPage,
    canActivate: [PrivateInviteGuard]
  },
  {
    path: 'invite-external/:id',
    component: ScreeningInvitationPage,
    canActivate: [PrivateInviteGuard]
  },
  {
    path: 'invite/:id/toggle-language',
    component: ScreeningInvitationPage,
    data: { toggleLanguage: true },
    canActivate: [PrivateInviteGuard]
  },
  {
    path: 'screenings/:screeningId/pre-check/:id',
    component: PreCheckConfirmationPage
  },
  {
    path: 'screenings/:screeningId/nda/:id',
    component: PreCheckNDAPage
  },
  {
    path: 'nda/:id',
    component: NdaSignPage
  },
  {
    path: 'nda-preview/:ndaId',
    component: NdaSignPage,
    data: { preview: true },
  },
  {
    path: 'nda-complete',
    component: NdaCompletePage
  },
  {
    path: 'faqs',
    component: FAQPage
  },
  {
    path: 'account/profile',
    component: UpdateProfilePage,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'account/screenings',
    component: UserScreeningsPage,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'verification',
    component: VerificationPage
  },
  {
    path: 'verification-error',
    component: VerificationPage,
    data: { error: true }
  },
  {
    path: 'reset-password',
    component: ResetPasswordPage,
    canActivate: [LoggedOutGuard]
  },
  {
    path: 'reset-password-error',
    component: ResetPasswordPage,
    canActivate: [LoggedOutGuard],
    data: { error: true }
  },
  {
    path: 'do-not-sell',
    component: DoNotSellPage
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsPage
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPage
  },
  {
    path: 'cookies',
    component: CookiesPolicyPage
  },
  {
    path: 'not-found',
    component: NotFoundPage
  },
  {
    path: 'registration-error',
    component: RegistrationErrorPage
  },
  {
    path: 'screening/nda/:id',
    component: NdaPage,
    canActivate: [ScreeningRegistrationGuard]
  },
  {
    path: '**',
    component: NotFoundPage
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})

export class Routing { }
