import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'not-found-page',
  styleUrls: ['./not-found-page.component.scss'],
  templateUrl: './not-found-page.component.pug'
})
export class NotFoundPage implements OnInit {

  constructor(private titleService: Title, private route: ActivatedRoute) { }

  public ngOnInit() {
    this.titleService.setTitle('Preview Free Movies - 404');
  }
}
