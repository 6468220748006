import { Component, OnInit } from '@angular/core';

import { AccountService } from '../../../../services/account.service';

import OktaSignIn from '@okta/okta-signin-widget';
// import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { BsModalRef } from 'ngx-bootstrap/modal';

import * as oktaConfig from 'common/okta.json';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'okta-modal',
  styleUrls: ['./okta-modal.component.scss'],
  templateUrl: './okta-modal.component.pug'
})
export class OktaModal implements OnInit {
  public showSuccessAlert: boolean;
  public showErrorAlert: boolean;
  public signIn: any;

  constructor(private accountService: AccountService, private dialog: BsModalRef, private cookieService: CookieService) {
    this.signIn = new OktaSignIn({
      useClassicEngine: true,
      clientId: oktaConfig.clientId,
      baseUrl: oktaConfig.issuer.split("/oauth2/")[0],
      logo: require('../../../assets/pfm-logo.png'),
      // Assumes there is an empty element on the page with an id of 'osw-container'
      el: '#osw-container',
      authParams: {
        issuer: oktaConfig.issuer,
        responseType: 'id_token',
        pkce: false
      },
      i18n: {
        en: {
          'primaryauth.title': 'Administrator Authenication',
          'primaryauth.username.placeholder': 'Okta Login',
          'primaryauth.username.tooltip': 'Enter your admin account email',
          'primaryauth.password.placeholder': 'Okta Password',
          'primaryauth.password.tooltip': 'Enter your password',
          'primaryauth.submit': 'Authenticate',
          // Errors
          'error.username.required': 'Please enter your login',
          'error.password.required': 'Please enter a password',
          'errors.E0000004': 'Authentication failed'
        }
      }
    });
  }

  public ngOnInit() {
    // no processing needed
    const redirectUri = window.location.href;
    this.signIn.showSignIn({
      redirectUri,
      //getAccessToken: false,
      //getIdToken: false
    }).then((res) => {
      // this.signIn.authClient.handleLoginRedirect(res.tokens);
      if (res?.tokens?.idToken?.idToken) {
        this.cookieService.set('okta_token', res.tokens.idToken.idToken, 0.375); // expire in 9 hours
        const landingPage = '/cms/dashboard/screenings/Navigation/screening-list';
        window.location.href = landingPage;
      }
    })
  }

  public ngOnDestroy() {
    this.signIn.remove();
  }

  public onKeyUp(value: any) {
    this.dialog.hide();
  }

}
