import { Component, ViewChild } from '@angular/core';

import { CheckboxInput } from '../../form-controls/checkbox-input';

@Component({
  selector: 'subscription-form',
  styleUrls: ['./subscription-form.component.scss'],
  templateUrl: './subscription-form.component.pug'
})
export class SubscriptionForm {
  @ViewChild(CheckboxInput, { static: true }) public checkbox: CheckboxInput;

  public valid() {
    return this.value();
  }

  public value() {
    return this.checkbox.value;
  }
}
