import { Injectable } from '@angular/core';

@Injectable()
class CompatibilityService {

  public isLetConstSupported(): boolean {
    try {
      let one: number;
      (one = 1, eval)('let letSupported = true; const constSupported = true;');
    } catch (e) {
      return false;
    }
    return true;
  }
}

export { CompatibilityService };
