import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { HTTPMethod, ISuccessResponse, NetworkService } from './network.service';
// import { CookieService } from 'ngx-cookie-service';

import { AccountData, AccountExpanded, Answer, RegistrationData, RegistrationExpanded, ScreeningDateFilterType } from '../../../common/interfaces';

import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class AccountService {
  public currentUser = new ReplaySubject<AccountExpanded>(1);

  constructor(private networkService: NetworkService) { }

  public verifyCurrentUser() {
    let currentToken = this.networkService.accessToken;
    if (currentToken.id) {
      this.getSelf().subscribe();
    } else {
      this.clearUser();
    }
  }

  public getSelf(tokenId?): Observable<AccountExpanded> {
    return this.networkService.httpRequest(HTTPMethod.GET, `Accounts/me`, {}, tokenId).pipe(
      tap(account => {
        // if (account && account.RoleMapping && account.RoleMapping.Role) {
        //   // save user roles, CMS requires an array even though users only have one role
        //   let roles = [{ name: account.RoleMapping.Role.name, description: account.RoleMapping.Role.description }];
        //   this.cookieService.set('roles', JSON.stringify(roles));
        // }
        this.currentUser.next(account);
      },
        error => this.clearUser()
      ));
  }

  public getBlacklist(userId): Observable<any[]> {
    return this.networkService.httpRequest(HTTPMethod.GET, `Accounts/${userId}/blacklist`)
  }

  public resubscribe(userId): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.PUT, 'Accounts/resubscribe', { id: userId })
  }

  public unsubscribe(userId): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.PUT, 'Accounts/unsubscribe', { id: userId })
  }

  public requestData(userId): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.POST, `Accounts/${userId}/dataAccessRequest`)
  }

  public register(accountInfo: AccountExpanded, answers: Array<Answer>, registrationData?: RegistrationData): Observable<AccountExpanded> {
    return this.networkService.httpRequest(HTTPMethod.POST, 'Accounts/register', { userInfo: accountInfo, answers, registrationData }).pipe(
      tap(account => this.currentUser.next(account))
    );
  }

  public updateRegistration(params: RegistrationData): Observable<AccountExpanded> {
    return this.networkService.httpRequest(HTTPMethod.POST, 'Accounts/me/updateRegistration', { params });
  }

  public signUp(email: string, password: string): Observable<AccountExpanded> {
    return this.networkService.httpRequest(HTTPMethod.POST, 'Accounts/signup', { email, password });
  }

  public login(email: string, password: string): Observable<AccountExpanded> {
    return this.networkService.httpRequest(HTTPMethod.POST, 'Accounts/signin', { email, password }).pipe(
      tap((account: any) => {
        if (account && account.emailVerified) {
          this.currentUser.next(account);
        }
      })
    );
  }

  public update(account?: AccountData, answers?: Array<Answer>): Observable<AccountExpanded> {
    return this.networkService.httpRequest(HTTPMethod.PUT, `Accounts/me`, { attributes: account, answers }).pipe(
      tap((account: any) => {
        if (account && account.emailVerified) {
          this.currentUser.next(account);
        }
      })
    );
  }

  public industryBan(account?: RegistrationData, answers?: Array<Answer>): Observable<AccountExpanded> {
    return this.networkService.httpRequest(HTTPMethod.PUT, `Accounts/industryBan`, { attributes: account, answers }).pipe(
      tap((account: any) => {
        if (account && account.emailVerified) {
          this.currentUser.next(account);
        }
      })
    );
  }

  public requestResetPassword(email: string, accessTokenId?: string): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.POST, 'Accounts/requestResetPassword', { email });
  }

  public resetPassword(userInfo: any, accessTokenId?: string): Observable<ISuccessResponse> {
    return this.networkService.httpRequest(HTTPMethod.POST, `Accounts/resetPassword`, { userInfo }, accessTokenId);
  }

  public getScreeningRegistrations(dateFilter?: ScreeningDateFilterType): Observable<RegistrationExpanded[]> {
    return this.networkService.httpRequest(HTTPMethod.GET, 'Accounts/me/ScreeningRegistrations', { dateFilter });
  }

  public resendVerificationEmail(email: string): Observable<AccountExpanded> {
    return this.networkService.httpRequest(HTTPMethod.POST, `Accounts/me/resendVerificationEmail`, { email });
  }

  public logout() {
    return this.networkService.httpRequest(HTTPMethod.POST, 'Accounts/logout');
  }

  public clearUser() {
    this.currentUser.next(null);
    this.networkService.clearAuthInfo();
  }

  public getRoles(): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.GET, `Roles?access_token=${this.getAccessToken().id}`);
  }

  public getRoleMappings(userId: number): Observable<any> {
    // tslint:disable-next-line
    let path = `RoleMappings?filter[where][principalId]=${userId}&access_token=${this.getAccessToken().id}`;
    return this.networkService.httpRequest(HTTPMethod.GET, path);
  }

  public getAccessToken = () => {
    return this.networkService.accessToken;
  }

  public verifyCaptcha(): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.GET, 'Accounts/validateCaptcha');
  }

  public addToDoNotSell(email: string, lastName: string, postalCode: string): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.POST, 'DoNotSell/add', { email, lastName, postalCode })
  }

  public logRegistrationURL(data:any): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.POST, 'RecruiterLogs/logURL', {data})
  }

  public updateRecruiterLog(id:number, data:any): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.PATCH, `RecruiterLogs/${id}`, data)
  }

  public verifyCode(email: string, token: string, accessTokenId?: string): Observable<AccountExpanded> {
    let path = `Accounts/verifyCode`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.POST, path, { token, email });
  }

  public resendCode(email: string): Observable<any> {
    return this.networkService.httpRequest(HTTPMethod.POST, `Accounts/resendVerificationCode`, { email });
  }
}

export { AccountService };

