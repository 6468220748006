import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HTTPMethod, NetworkService } from './network.service';

import { Setting, SettingType } from '../../../common/interfaces';

@Injectable()
class SettingService {
  constructor(private networkService: NetworkService) { }

  public getById(id: SettingType): Observable<Setting> {
    return this.networkService.httpRequest(HTTPMethod.GET, `Settings/${id}`);
  }
}

export { SettingService };
