// Based on: https://blog.brunoscopelliti.com/angularjs-directive-to-test-the-strength-of-a-password/

import { Component, Input, OnChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'password-strength-bar',
  styles: [
    `
    ul#strengthBar {
        display:inline;
        list-style:none;
        margin:0;
        margin-left:15px;
        padding:0;
        vertical-align:2px;
    }
    .point:last {
        margin:0 !important;
    }
    .point {
        background:#DDD;
        border-radius:2px;
        display:inline-block;
        height:5px;
        margin-right:1px;
        width:20px;
    }`
  ],
  template: `
    <div id="strength" #strength>
        <small>{{barLabel}}</small>
        <ul id="strengthBar">
            <li class="point" [style.background-color]="bar0"></li><li class="point" [style.background-color]="bar1"></li><li class="point" [style.background-color]="bar2"></li><li class="point" [style.background-color]="bar3"></li><li class="point" [style.background-color]="bar4"></li>
        </ul>
    </div>
`
})
export class PasswordStrengthBar implements OnChanges {
  private static measureStrength(p) {
    let _force = 0;
    let _regex = /[$-/:-?{-~!"^_`\[\]]/g; // "

    let _lowerLetters = /[a-z]+/.test(p);
    let _upperLetters = /[A-Z]+/.test(p);
    let _numbers = /[0-9]+/.test(p);
    let _symbols = _regex.test(p);

    let _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];

    let _passedMatches = 0;
    for (let _flag of _flags) {
      _passedMatches += _flag === true ? 1 : 0;
    }

    _force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    _force += _passedMatches * 10;

    // penality (short password)
    _force = p.length <= 6 ? Math.min(_force, 10) : _force;

    // penality (poor variety of characters)
    _force = _passedMatches === 1 ? Math.min(_force, 10) : _force;
    _force = _passedMatches === 2 ? Math.min(_force, 20) : _force;
    _force = _passedMatches === 3 ? Math.min(_force, 40) : _force;

    return _force;
  }
  @Input() public passwordToCheck: string;
  @Input() public barLabel: string;
  public bar0: string;
  public bar1: string;
  public bar2: string;
  public bar3: string;
  public bar4: string;

  private colors = ["#F00", "#F90", "#FF0", "#9F0", "#0F0"];

  public ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    console.log('does it hit changes?')
    let password = changes["passwordToCheck"].currentValue;
    this.setBarColors(5, "#DDD");
    if (password) {
      let c = this.getColor(PasswordStrengthBar.measureStrength(password));
      this.setBarColors(c.idx, c.col);
    }
  }

  private getColor(s) {
    let idx = 0;
    if (s <= 10) {
      idx = 0;
    } else if (s <= 20) {
      idx = 1;
    } else if (s <= 30) {
      idx = 2;
    } else if (s <= 40) {
      idx = 3;
    } else {
      idx = 4;
    }
    return {
      idx: idx + 1,
      col: this.colors[idx]
    };
  }

  private setBarColors(count, col) {
    for (let _n = 0; _n < count; _n++) {
      this["bar" + _n] = col;
    }
  }
}
