import { AccountExpanded, AccountRoleType } from '../../../../../../common/interfaces/account';
import { AccountService, CompatibilityService } from '../../../../services';
// import { PasswordStrengthBar } from '../../../common/passwordStrengthBar';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Errors, StatusError } from '../../../../../../common/lib/Errors';
import { ForgotPasswordModal } from '../forgot-password-modal';
import { OktaModal } from '../okta-modal';

@Component({
  selector: 'login-modal',
  styleUrls: ['./login-modal.component.scss'],
  templateUrl: './login-modal.component.pug'
})
export class LoginModal {
  public loginError: string;
  public showAdminForm: boolean;
  private account: AccountExpanded;
  public shouldRedirect: boolean;

  constructor(private modalService: BsModalService, public dialog: BsModalRef,
    private router: Router, private accountService: AccountService,
    private compatibilityService: CompatibilityService, private cookieService: CookieService) {

  }

  public onFormChanged() {
    this.loginError = '';
  }

  public onForgotPasswordClicked() {
    this.modalService.show(ForgotPasswordModal);
  }

  public onLoginFormSubmitted(valid: boolean, values: any) {
    if (!valid) {
      this.loginError = 'Please enter a valid email and password';
      return;
    }
    this.accountService.login(values.email, values.password).subscribe(
      account => {
        this.account = account;
        this.redirectUser();
      },
      err => {
        let error: StatusError = err;
        if (error.message === Errors.messages.ERROR_NEEDS_VERIFICATION) {
          this.loginError = 'Account needs verification. Please follow the link in your email to continue';
        } else {
          this.loginError = 'Error logging in. Please check your email/password and try again.';
        }
      }
    )
      ;
  }

  private redirectUser() {
    // clear session info for CMS
    // document.cookie = 'username=; path=/cms/; expires=' + new Date(0).toUTCString();
    // document.cookie = 'userId=; path=/cms/; expires=' + new Date(0).toUTCString();
    // document.cookie = 'accessToken=; path=/cms/; expires=' + new Date(0).toUTCString();
    // document.cookie = 'session=; path=/cms/; expires=' + new Date(0).toUTCString();
    // document.cookie = 'roles=; path=/cms/; expires=' + new Date(0).toUTCString();

    switch (this.account.roleId) {
      case AccountRoleType.User:
      case AccountRoleType.Anonymous:
        this.dialog.hide();
        this.setUserRoles();
        if (this.shouldRedirect) {
          this.router.navigate(['/account/screenings']);
        }
        break;
      case AccountRoleType.Recruiter:
      case AccountRoleType.Administrator:
      case AccountRoleType.Limited:
      case AccountRoleType.Manager:
      case AccountRoleType.SuperAdmin:
        if (this.compatibilityService.isLetConstSupported()) {
          this.setUserRoles(true);
        } else {
          this.showBrowserUnsupportedModal();
        }
        break;
      default:
        break;
    }
  }

  private showBrowserUnsupportedModal() {
    // this.modal.alert()
    //   .dialogClass('modal-dialog browser-unsupported')
    //   .title('Unsupported Browser')
    //   .body(`Your browser is not supported by the admin/recruiter portal. Please use an up-to-date version of
    //         Chrome, Firefox, or Safari for the best experience`)
    //   .open();
  }

  private setUserRoles(loginCMS?: boolean) {
    this.accountService.getRoles().subscribe(
      roles => {
        this.accountService.getRoleMappings(this.account.id).subscribe(
          roleMappings => {
            // this.cookieService.set('roles', JSON.stringify(roleMappings), 0.375, '/'); // expire in 9 hours
            // Get role name and description
            for (let i in roleMappings) {
              const roleMap = roleMappings[i];
              const role = roles.find((x) => x.id === roleMap.roleId);
              if (role) {
                // tslint:disable-next-line
                roleMap.name = role['name'];
                // tslint:disable-next-line
                roleMap.description = role['description'];
              }
            }
            if (loginCMS) {
              this.loginCMS(roleMappings);
            }
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  private loginCMS(roles: any) {
    const roleId = this.account.roleId;

    const isAdmin = [AccountRoleType.SuperAdmin, AccountRoleType.Administrator, AccountRoleType.Manager, AccountRoleType.Limited].includes(roleId);
    if (isAdmin) {
      const accessToken = this.accountService.getAccessToken();
      // tslint:disable-next-line
      this.account['roles'] = roles;

      if (this.account && this.account.Kids && this.account.Kids.length) {
        delete this.account.Kids;
      }
      const user = {
        firstName: this.account.firstName,
        lastName: this.account.lastName,
        email: this.account.email,
        roleId: this.account.roleId
      }
      const session = {
        id: accessToken.id,
        ttl: accessToken.ttl,
        created: accessToken.created,
        username: this.account.username,
        user
      };
      let username = this.account.username ? this.account.username : '';
      this.cookieService.set('username', username, 0.375, '/'); // expire in 9 hours
      this.cookieService.set('userId', this.account.id.toString(), 0.375, '/'); // expire in 9 hours
      this.cookieService.set('accessToken', accessToken.id, 0.375, '/'); // expire in 9 hours
      this.cookieService.set('session', JSON.stringify(session), 0.375, '/'); // expire in 9 hours
      this.cookieService.set('roles', JSON.stringify(roles), 0.375, '/'); // expire in 9 hours

      this.modalService.show(OktaModal);
    }

    // tslint:disable
    let landingPage = '/cms/dashboard/';

    if (this.account.roleId === AccountRoleType.Recruiter) {
      landingPage += 'Recruiter/Navigation/landing';
    } else {
      landingPage += 'screenings/Navigation/screening-list'
    }

    this.dialog.hide();

    if (this.account.roleId === AccountRoleType.Recruiter) {
      const landingPage = '/cms/dashboard/Recruiter/Navigation/landing';
      window.location.href = landingPage;
    }

  }
}
