import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pfm-footer',
  styleUrls: ['./pfm-footer.component.scss'],
  templateUrl: './pfm-footer.component.pug'
})
export class PfmFooter implements OnInit {
  public today: Date;

  public ngOnInit() {
    this.today = new Date();
  }

}
