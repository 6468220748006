import { Component, Input, OnInit, QueryList, ViewChildren, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CheckboxInput } from '../checkbox-input';

// default is Horizontal
export type CheckgroupStyle = 'Vertical' | 'Horizontal'

@Component({
  selector: 'checkgroup',
  styleUrls: ['./checkgroup.component.scss'],
  templateUrl: './checkgroup.component.pug',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => Checkgroup),
    multi: true
  }]
})
export class Checkgroup implements ControlValueAccessor, OnInit {
  @ViewChildren(CheckboxInput) public checkboxes: QueryList<CheckboxInput>;
  @Input() public options = new Array<string>();
  @Input() public style: string;
  @Input() public value: any;
  @Input() public noneIndex: any;
  @Input() public questionId: any;
  @Input() public guestNumber: any;
  @Input() public isInvalid: any;
  @Input() public showHighlightedError: boolean;
  private noneLabel: string;

  public ngOnInit() {
    let initValue = this.value;
    let vals = new Array(this.options.length).fill(false);
    this.value = this.options.reduce((p, c, i) => (p[c] = vals[i], p), {});
    if (Number.isInteger(this.noneIndex)) {
      this.noneLabel = this.options[this.noneIndex]
    }
    if (initValue && initValue.length) {
      Object.assign({}, this.value, Object.fromEntries(initValue))
    }
  }

  public writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  public onItemChecked(item: any, index: number) {
    this.value[item.label] = item.value;
    if (this.noneLabel) {
      if (item.label === this.noneLabel) {
        Object.entries(this.value).forEach(([label, value]) => {
          if (label !== this.noneLabel) {
            this.value[label] = false;
          }
        });
      } else if (this.value[this.noneLabel]) {
        this.value[this.noneLabel] = false;
      }
    }
    this.propagateChange(this.value);
  }

  public propagateChange = (_: any) => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public ngDoCheck() {
    if (this.checkboxes) {
      // update value (check-state) of checkbox based on input value
      this.checkboxes.forEach((checkbox: any) => {
        checkbox.value = this.value[checkbox.label];
      });
    }
  }
}
