import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { AccountService } from '../../../../services/account.service';
import { PfmHeader } from '../../pfm-header';

@Component({
  selector: 'reset-password-page',
  styleUrls: ['./reset-password-page.component.scss'],
  templateUrl: './reset-password-page.component.pug'
})
export class ResetPasswordPage implements OnInit {
  @ViewChild(PfmHeader, { static: false }) public pfmHeader: PfmHeader;
  public isValid: boolean;
  public isResetting: boolean;
  public showSuccessAlert: boolean;
  public showErrorAlert: boolean;
  private accessTokenId: string;

  constructor(private titleService: Title, private route: ActivatedRoute, private accountService: AccountService) {

  }

  public ngOnInit() {
    const TOKEN_KEY = 'access_token';
    const ERROR_KEY = 'error';
    this.isValid = !this.route.snapshot.data[ERROR_KEY];
    this.accessTokenId = this.route.snapshot.queryParams[TOKEN_KEY];
    this.titleService.setTitle('Preview Free Movies - Registration Successful');
  }

  public onLoginClicked() {
    this.pfmHeader.presentLogInModal();
  }

  public onPasswordFormSubmitted(newPassword: string) {
    this.isResetting = true;
    let userInfo = { newPassword };
    this.accountService.resetPassword(userInfo, this.accessTokenId).subscribe(
      success => {
        this.showSuccessAlert = true;
      },
      error => {
        this.showErrorAlert = true;
        this.isResetting = false;
      }
    );
  }
}
