import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { NDAService } from '../../../../services';
import { CheckboxInput } from '../../form-controls';

import { RegistrationExpanded } from '../../../../../../common/interfaces/index';

import SignaturePad from 'signature_pad';

@Component({
  selector: 'nda-form',
  styleUrls: ['./nda-form.component.scss'],
  templateUrl: './nda-form.component.pug'
})
export class NdaForm implements OnInit, AfterViewInit {

  @ViewChild('container', { static: false }) public container: ElementRef;
  @ViewChild(CheckboxInput, { static: false }) public checkbox: CheckboxInput;
  @ViewChild('canvas', { static: false }) public canvas: ElementRef;
  @ViewChild('pdfViewer', { static: false }) public pdfViewer: any; //for extra methods
  @Input() public registration: RegistrationExpanded;

  public today = new Date();
  public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  public ndas: Array<any>;
  public index: number = -1;
  public ndaCount: number;
  public customFields: any = {};
  public ndaPdf: any;

  private signaturePad: any;

  private signaturePadOptions: Object = {
    minWidth: 2,
    maxWidth: 3,
    canvasWidth: 400,
    canvasHeight: 200,
    backgroundColor: 'rgb(255, 255, 255)'
  }

  constructor(private ndaService: NDAService) { }

  public ngOnInit() {
    this.ndas = this.registration.Screening.NDAs || []
    this.ndaCount = this.ndas.length - 1
    if (!!this.ndas.length) {
      this.index = 0
      this.loadNda(this.ndas[this.index])
    }
  }

  public ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, this.signaturePadOptions)
  }

  public accepted() {
    return !!this.checkbox && !!this.checkbox.value && Object.values(this.customFields).reduce((p, c) => !!p && !!c, true)
  }

  public next() {
    this.checkbox.value = false;
    this.index++
    this.loadNda(this.ndas[this.index])
  }

  private loadNda(nda: any) {
    nda.customFields.forEach(field => Object.defineProperty(this.customFields, field, { writable: true, enumerable: true }))
    this.ndaService.getPDF(nda.url).subscribe((resp: ArrayBuffer) => {
      const pdfFile = new Blob([resp], { type: 'application/pdf' });
      this.pdfViewer.pdfSrc = pdfFile;
      this.pdfViewer.refresh();
    })
  }

  public clearSignature() {
    this.signaturePad.clear()
  }

  public undoSignature() {
    let data = this.signaturePad.toData();

    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  public isSigned() {
    return !this.signaturePad.isEmpty()
  }

  public signatureURI() {
    return this.signaturePad.toDataURL()
  }

  public fields() {
    return this.customFields
  }

}
