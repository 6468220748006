import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';

export interface CanComponentDeactivate {
  submitted: boolean;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private router: Router) {
  }

  public canDeactivate(component: CanComponentDeactivate) {
    return component.submitted ? true : confirm('Unsaved changes will be lost. Are you sure you want to continue?');
  }
};
