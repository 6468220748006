import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HTTPMethod, NetworkService } from './network.service';

import { City } from '../../../common/interfaces';

@Injectable()
class CityService {

    constructor(private networkService: NetworkService) { }

    public get(): Observable<Array<City>> {
        return this.networkService.httpRequest(HTTPMethod.GET, `Cities/`);
    }
}

export { CityService };
