import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
  private config: any = null;

  constructor() {
    this.config = (window as any).config;
  }

  public get(keypath: string): any {
    const travel = regexp => String.prototype.split.call(keypath, regexp).filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), this.config);
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === this.config ? undefined : result;
  }
}
