import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from '../../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordModal } from '../../modals/forgot-password-modal';

@Component({
  selector: 'email-verification-failed-page',
  styleUrls: ['./email-verification-failed-page.component.scss'],
  templateUrl: './email-verification-failed-page.component.pug'
})
export class EmailVerificationFailedPage implements OnInit, AfterViewInit {
  public email: string;
  public showErrorAlert?: boolean;
  public verifyCodeForm: FormGroup;
  private firstEl: HTMLInputElement;
  public submitted: boolean = false;
  public showError: any = null;
  public success: any = null;
  public waiting: boolean = false;

  @ViewChild('input') inputEl: ElementRef;

  constructor(
    private titleService: Title,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService
  ) {}

  public ngOnInit() {
    let userEmail = this.route.snapshot.queryParams.email;
    this.email = userEmail ? userEmail : '';
    this.titleService.setTitle('Confirm Email');

    this.verifyCodeForm = new FormGroup(Array.from(Array(6)).reduce((p, c, i) => {
      p[`${i}`] = new FormControl(null, Validators.required);
      return p;
    }, {}));
  }

  public ngAfterViewInit() {
    this.initializeEventListeners();
  }

  private initializeEventListeners() {
    const els = Array.from(document.getElementsByClassName('verify'));
    const subEl = document.getElementById('submit');

    els.forEach((el, i) => {
      if (!i) {
        el.addEventListener("paste", (event: ClipboardEvent) => {
          event.preventDefault();
          const paste = event.clipboardData.getData("text");
          this.verifyCodeForm.setValue(paste.substring(0, 6).split('').reduce((p, c, ix) => {
            p[ix] = c.replace(/[^0-9]/g, '');
            return p;
          }, {}));
        });
      }

      el.addEventListener("keypress", (event: KeyboardEvent) => {
        if (!(/^[0-9]$/.test(event.key) || event.key === "Backspace" || event.key === "Delete")) {
          event.preventDefault();
        }
      });

      el.addEventListener("keyup", (event: KeyboardEvent) => {
        const target = event.target as HTMLInputElement;
        if (event.key === 'v' && event.ctrlKey) {
          if (subEl) subEl.focus();
        } else if (!!target.value && /^[0-9]$/.test(event.key)) {
          const next = target.nextElementSibling as HTMLInputElement;
          if (next) {
            next.focus();
            next.select();
          } else {
            if (subEl) subEl.focus();
          }
        }
      });
    });

    this.firstEl = els[0] as HTMLInputElement;
    if (this.firstEl) this.firstEl.focus();
  }

  public resendEmailButtonPressed(email: string) {
    this.showErrorAlert = false;
    this.accountService.resendCode(email).subscribe(
      updated => {
        this.router.navigate(["/registration/confirmation"], {
          queryParams: { email: email },
        });
      },
      err => {
        this.showErrorAlert = true;
      }
    );
  }

  public onForgotPasswordClicked() {
    this.modalService.show(ForgotPasswordModal);
  }
}