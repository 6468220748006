import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { RegistrationExpanded } from '../../../../../common/interfaces';
import { RegistrationService } from '../../../services';

@Component({
  selector: 'user-screenings-table',
  styleUrls: ['./user-screenings-table.component.scss'],
  templateUrl: './user-screenings-table.component.pug'
})
export class UserScreeningsTable {
  @Input() public data: RegistrationExpanded[];
  @Input() public showStatus: boolean;

  constructor(private router: Router, private registrationService: RegistrationService) { }

  public onTitleClicked(id: number, state: string) {
    console.log(this.data);
    if (!['Confirmed', 'Pre-Checked'].includes(state) || !this.showStatus) { return; }
    this.registrationService.getForScreening(id).subscribe(
      registration => {
        this.router.navigate(['/screenings', registration.screeningId, 'confirmation', registration.id]);
      },
      error => {
        console.log('error: ', error);
      }
    );
  }
}
