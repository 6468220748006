import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { AccountRoleType, ScreeningInvitationRendered } from '../../../../../../common/interfaces';
import { AccountService, ScreeningInvitationService } from '../../../../services';
import { HTMLToPlainTextPipe } from '../../../common/htmlToPlainText.pipe';

import { distinctUntilChanged } from 'rxjs/operators';
import * as DOMPurify from 'dompurify';

declare const ga: Function;

@Component({
  selector: 'screening-invitation-page',
  styleUrls: ['./screening-invitation-page.component.scss'],
  templateUrl: './screening-invitation-page.component.pug'
})
export class ScreeningInvitationPage implements OnInit {
  @ViewChild('dataContainer', { static: false }) public dataContainer: ElementRef;
  public html: any;
  public imgSrc: any;
  public htmlHeight: Number;
  public text: any;
  public copyHTMLSuccess: boolean;
  public copyTextSuccess: boolean;
  public isAdmin: boolean;
  public logId: number;
  private isExternal: boolean = false;
  private inboundUrl: string = "";
  private urlToVerify: string = "";
  private recruit_id: string = "";
  private memberId: string = "";
  private hash: string = "";

  constructor(private route: ActivatedRoute, private router: Router,
    private titleService: Title, private screeningInvitationService: ScreeningInvitationService,
    private accountService: AccountService, private sanitizer: DomSanitizer) {
  }

  public ngOnInit() {
    this.titleService.setTitle('Screening Invitation Preview');
    // tslint:disable
    let invitationId = this.route.snapshot.params['id']
    let toggleLanguage = this.route.snapshot.data['toggleLanguage'];
    // tslint:enable

    const currentUrl = this.route.snapshot.url.join("/");
    this.isExternal = currentUrl.includes("invite-external");
    this.inboundUrl = window.location.href;
    this.urlToVerify = this.router.url;
    if (this.isExternal) {
      this.recruit_id = this.route.snapshot.queryParams["recruiterId"] || null;
      this.memberId = this.route.snapshot.queryParams["memberId"] || null;
      this.hash = this.route.snapshot.queryParams["hash"] || null;

      if (this.recruit_id && this.memberId) {
        const payload = {
          journey: "Attendance",
          inboundUrl: this.inboundUrl,
          urlToVerify: this.urlToVerify,
          recruiterId: this.recruit_id,
          memberId: this.memberId,
          hash: this.hash,
        };
        this.logRegistrationURL(payload, invitationId, toggleLanguage);
      } else {
        this.router.navigate(["registration-error"]);
      }
    } else {
      this.screeningInvitationService.generateTemplate(invitationId, toggleLanguage).subscribe(
        invitation => this.getInviteHtml(invitation),
        error => {
          if (error.status === 404) {
            this.router.navigateByUrl('/');
          }
        }
      );
    }

    this.accountService.currentUser.subscribe(
      currentUser => {
        if (currentUser) {
          // only show html/plain text for admins
          this.isAdmin = [AccountRoleType.SuperAdmin, AccountRoleType.Administrator, AccountRoleType.Manager, AccountRoleType.Limited].includes(currentUser.RoleMapping.roleId);
        }

        if (!currentUser || !this.isAdmin) {
          if (!this.isAdmin) {
            this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
              if (current instanceof NavigationEnd) {
                return previous.url === current.url;
              }
              return true;
            })).subscribe((navigation: any) => {
              ga('set', 'page', navigation.url);
              ga('send', 'pageview');
            });
          }
        }

      }
    );

  }

  public logRegistrationURL(payload: any, invitationId:any, toggleLanguage:any) {
    this.accountService.logRegistrationURL(payload).subscribe(
      (res) => {
        if (res && typeof res === "string") {
          this.router.navigate(["registration-error"]);
        } else if (res && res.id) {
          this.logId = res.id;

          this.screeningInvitationService.generateTemplate(invitationId, toggleLanguage, this.logId).subscribe(
            invitation => this.getInviteHtml(invitation),
            error => {
              if (error.status === 404) {
                this.router.navigateByUrl('/');
              }
            }
          );
        }
      },
      (error) => {}
    );
  }

  public onHTMLCopied() {
    this.copyHTMLSuccess = true;
    this.copyTextSuccess = false;
  }

  public onTextCopied() {
    this.copyTextSuccess = true;
    this.copyHTMLSuccess = false;
  }

  private getInviteHtml(invitation: ScreeningInvitationRendered) {
    this.html = invitation.html;
    // Need to bypass angualr sanitizing to display base64 string.
    // In actual emails from Mandrill, img src will be rendered with the rest of the HTML
    if (invitation.imgString) {
      let str = 'data:image/png;base64, ' + invitation.imgString;
      this.imgSrc = this.sanitizer.bypassSecurityTrustResourceUrl(str);
    }
    this.text = new HTMLToPlainTextPipe().transform(invitation.html);
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
      }
    });
    this.dataContainer.nativeElement.contentDocument.body.innerHTML = DOMPurify.sanitize(invitation.html);
    setTimeout(() => {
      this.htmlHeight = this.dataContainer.nativeElement.contentDocument.childNodes[0].offsetHeight;
    }, 0);
  }
}
