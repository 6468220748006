import { BaseModel } from './base';
import { RuleSet } from './ruleSet';
import { Screening } from './screening';

type EmailTemplateType = 'Default' | 'Custom';
interface EmailTemplate extends BaseModel {
  title?: string;
  description?: string;
  subject?: string;
  message?: string;
  displayOrder?: number;
  type?: EmailTemplateType;
}

interface EmailAudienceOptions {
  screeningId: number;
  registrationIds?: Array<number>;
  type?: EmailTemplateType;
  ruleSetId?: number;
  subject?: string;
  message?: string;
  configJSON?: any;
  shouldCC?: boolean;
  deliveryTime?: Date;
  identifier?: string;
  RuleSet?: RuleSet;
  Screening?: Screening;
  includeLink?: boolean;
  template?: string;
  campaignId: number;
  recipientIds?: Array<number>;
}

export const MOVIE_TITLE = '[TITLE]';
export const VENUE_NAME = '[VENUE]';
export const SCREENING_DATE = '[DATE]';
export const SCREENING_TIME = '[TIME]';
export const LINK = '[LINK]';
export const FIRST_NAME = '[FIRST_NAME]';
export const LAST_NAME = '[LAST_NAME]';
export const RECRUITER_CODE = '[RECRUITER_CODE]';
export const SCREENING_PASSWORD = '[SCREENING_PASSWORD]';
export const CONFIRMATION_MESSAGE = '[CONFIRMATION_MESSAGE]';
export const ROLE = '[ROLE]';
// tslint:disable-next-line
export const DEFAULT_CUSTOM_TEMPLATE_VARS: Array<TemplateVariableName> = [MOVIE_TITLE, VENUE_NAME, SCREENING_DATE, SCREENING_TIME];
export const DEFAULT_CAMPAIGN_MESSAGE_TEMPLATE_VARS: Array<any> = [FIRST_NAME, LAST_NAME];
export type TemplateVariableName = '[TITLE]' | '[VENUE]' | '[DATE]' | '[TIME]' | '[LINK]'
                                  | '[RECRUITER_CODE]' | '[SCREENING_PASSWORD]' | '[FIRST_NAME]'
                                  | '[CONFIRMATION_MESSAGE]' | '[ROLE]';
export const FORWARD_EMAIL_VARS = [FIRST_NAME, LINK, MOVIE_TITLE, VENUE_NAME,
                                   SCREENING_DATE, SCREENING_TIME, RECRUITER_CODE, SCREENING_PASSWORD];
export { EmailTemplate, EmailTemplateType, EmailAudienceOptions }
