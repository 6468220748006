import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { AccountExpanded, RegistrationExpanded, ScreeningExpanded } from '../../../../../../common/interfaces';
import { AccountService, RegistrationService, ScreeningService, SharedService } from '../../../../services';

import { distinctUntilChanged } from 'rxjs/operators';
import * as DOMPurify from 'dompurify';

declare const ga: Function;

@Component({
  selector: 'screening-confirmation-page',
  styleUrls: ['./screening-confirmation-page.component.scss'],
  templateUrl: './screening-confirmation-page.component.pug'
})
export class ScreeningConfirmationPage implements OnInit {
  public showEmailSuccessAlert: boolean;
  public showEmailErrorAlert: boolean;
  public showResendSuccessAlert: boolean;
  public showResendErrorAlert: boolean;
  public showSignUpErrorAlert: boolean;
  public showSignUpSuccessAlert: boolean;
  public showCancelConfirmAlert: boolean;
  public isOnlineEvent: boolean;
  public bypassNDA: boolean;
  public isCancelled: boolean;
  public qrCodeData = '';
  public qrCodeString = '';
  public forwardEmails: string[];
  public ticketPageMessage: string;

  public screening: ScreeningExpanded = { Venue: {} };
  public registration: RegistrationExpanded;
  private registrationConfirmationObj: any = {};
  public account: AccountExpanded = {};
  public screeningAddress: string;
  public recruiterCode: any;
  public accessTokenId: string;
  public mapLink = '';
  public onlineVenueLinkLabel = '';
  public onlineVenueLink = '';
  public momentFormat = 'h:mma z';
  public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //tslint:disable
  constructor(private route: ActivatedRoute, private router: Router,
    private titleService: Title, private accountService: AccountService,
    private screeningService: ScreeningService, private registrationService: RegistrationService,
    private sharedService: SharedService, private cookieService: CookieService) {
    let id: number;
    this.route.params.forEach((params: Params) => {
      const ID_KEY = 'registrationId';
      id = +params[ID_KEY];
    });
    const TOKEN_KEY = 'access_token';
    let accessTokenId = this.route.snapshot.queryParams[TOKEN_KEY];
    if (accessTokenId) {
      this.accessTokenId = accessTokenId;
    } else {
      this.accessTokenId = this.cookieService.get('accessToken');
    }
    this.accountService.currentUser.subscribe(
      currentUser => {
        if (currentUser) {
          this.account = currentUser;
          this.qrCodeData = `${id}`
        }
      }
    );
    router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    })).subscribe((navigation: any) => {
      ga('set', 'page', navigation.url);
      ga('send', 'pageview');
    });
  }

  // tslint:enable
  public ngOnInit() {
    this.registration = this.sharedService.getScreeningRegistration();
    this.recruiterCode = this.sharedService.getRecruiterCode();
    this.getRegistrationData();
    this.registrationConfirmationObj = this.sharedService.getRegistrationConfirmationObj()
    this.showCancelConfirmAlert = false;
    this.isCancelled = false;
    this.forwardEmails = []
  }

  public onResendConfirmationEmailClicked() {
    /*
    NOTE: This is calling the wrong API, we should be calling resendDigitialConfirmation
    INSTEAD of sendDigitalConfirmationEmail, which is causing the server to crash
    */
    const screeningId = this.registration && this.registration.screeningId ? this.registration.screeningId : null
    this.screeningService.resendConfirmations(screeningId, [this.registration.id]).subscribe(
      res => this.showResendSuccessAlert = true,
      err => this.showResendErrorAlert = true
    );
  }

  public onSignNDAClicked() {
    // this.router.navigate(['/screenings', this.screening.id, 'nda', this.encryptedId]);
  }

  public onUpdateGuestInfoClicked() {
    this.router.navigate(['update'], { relativeTo: this.route, queryParams: { access_token: this.accessTokenId } });
  }

  public onCancelConfirm() {
    let id = this.registration.id;
    this.registrationService.cancel(+id, this.accessTokenId).subscribe(
      success => {
        this.sharedService.setScreeningRegistration({});
      }
    );
    this.isCancelled = true;
  }

  public onCancelClicked() {
    this.showCancelConfirmAlert = !this.showCancelConfirmAlert;
  };

  // public onEmailFormSubmitted(emailForm: FormGroup) {
  //   let invitationId = null
  //   if (this.registration.ScreeningInvitation && this.registration.ScreeningInvitation.id) {
  //     invitationId = this.registration.ScreeningInvitation.id
  //   }
  //   this.showEmailErrorAlert = false;
  //   let emails = new Array<string>();
  //   for (let value in emailForm) {
  //     if (emailForm.hasOwnProperty(value) && emailForm[value].length > 0) {
  //       emails.push(emailForm[value]);
  //     }
  //   }
  //   let name = this.registration.Account ? `${this.registration.Account.firstName} ${this.registration.Account.lastName}` : null;
  //   this.screeningService.forwardInvites(this.screening.id, emails, name, invitationId, this.recruiterCode).subscribe(
  //     res => this.showEmailSuccessAlert = true,
  //     err => this.showEmailErrorAlert = true
  //   );
  // }

  public onEmailFormSubmitted() {
    let invitationId = null
    if (this.registration.ScreeningInvitation && this.registration.ScreeningInvitation.id) {
      invitationId = this.registration.ScreeningInvitation.id
    }
    this.showEmailErrorAlert = false;
    let emails = this.forwardEmails;
    let name = this.registration.Account ? `${this.registration.Account.firstName} ${this.registration.Account.lastName}` : '';
    this.screeningService.forwardInvites(this.screening.id, emails, name, invitationId, this.recruiterCode).subscribe(
      (res) => {
        this.forwardEmails = []
        this.showEmailSuccessAlert = true
      },
      err => this.showEmailErrorAlert = true
    );
  }

  public onPasswordFormSubmitted(password: any) {
    this.showSignUpErrorAlert = false;
    this.accountService.signUp(this.account.email, password.password).subscribe(
      res => this.showSignUpSuccessAlert = true,
      err => this.showSignUpErrorAlert = true
    );
  }

  public onPrintButtonClicked() {
    // TODO: REVISE
    [].forEach.call(document.querySelectorAll('.dnp'), function (el: any) {
      el.style.display = 'none';
    });
    [].forEach.call(document.querySelectorAll('.cookie-law-wrapper'), function (el: any) {
      el.style.display = 'none';
    });



    let printable = document.getElementById('printable');
    let clone = printable.cloneNode(true);

    let printSection = document.getElementById('print-section');
    printSection.appendChild(clone);



    window.print();
    // TODO: REVISE
    [].forEach.call(document.querySelectorAll('.dnp'), function (el: any) {
      el.style.display = 'inline-block';
    });
    [].forEach.call(document.querySelectorAll('.cookie-law-wrapper'), function (el: any) {
      el.style.display = 'flex';
    });

  }

  private getRegistrationData() {
    let id: number;
    this.route.params.forEach((params: Params) => {
      const ID_KEY = 'registrationId';
      id = +params[ID_KEY];
    });
    if (!this.registration.Screening || !this.registration.Account || this.registration.id !== id) {
      this.registrationService.getById(id, true, this.accessTokenId).subscribe(
        registration => {
          this.registration = registration;
          let regObj = {
            Account: {
              firstName: registration.Account.firstName,
              lastName: registration.Account.lastName,
              email: registration.Account.email,
              birthDate: registration.Account.birthDate,
              gender: registration.Account.gender,
              ethnicity: registration.Account.ethnicity
            },
            id: registration.id,
            state: registration.state,
            screeningId: registration.screeningId
          }
          this.registrationConfirmationObj = this.sharedService.setRegistrationConfirmationObj(regObj)
          let regString = JSON.stringify(regObj);
          this.qrCodeData = regString;
          this.qrCodeString = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${regString}`;
          this.recruiterCode = this.getOptionalProperty(registration, 'recruiterCode');
          this.sharedService.setRegistrationConfirmationObj(regObj)
          this.setRegistrationData();
        },
        err => {
          console.log('Error getting registration data: ', err);
          if (err && err.status === 401) {
            this.router.navigate(['/not-found']);
          }
        }
      );
    } else {
      this.recruiterCode = this.getOptionalProperty(this.registration, 'recruiterCode');
      this.setRegistrationData();
    }
  }

  private setRegistrationData() {
    let registration = this.registration;
    this.sharedService.setScreeningRegistration(registration);
    this.sharedService.setRecruiterCode(this.getOptionalProperty(registration, 'recruiterCode'));
    this.sharedService.setRecruiterId(registration.recruiterId);
    const screening = registration.Screening;
    if (screening && !registration.Screening.Venue) {
      this.momentFormat = 'h:mma';
    }
    this.screening = registration.Screening;
    if(this.screening.Venue && this.screening.Venue.name && !this.screening.Venue.isOnline && !['virtuworks screening room', 'virtu works screening room'].includes(this.screening.Venue.name.toLowerCase())){
      this.timezone = this.screening.Venue && this.screening.Venue.timezone || this.timezone;
    }
    if (this.screening.ticketPageMessage) {
      if (this.screening.minorNdaSigningAge && this.screening.minorNdaAssigningAge) {
        this.screening.ticketPageMessage = this.screening.ticketPageMessage.replace('(lower age)',this.screening.minorNdaAssigningAge.toString()).replace('(higher age)',this.screening.minorNdaSigningAge.toString());
      }
      DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        if ('target' in node) {
          node.setAttribute('target', '_blank');
          node.setAttribute('rel', 'noopener');
        }
      });
      this.ticketPageMessage = DOMPurify.sanitize(this.ticketPageMessage)
    }
    if (registration.ScreeningInvitation && registration.ScreeningInvitation.title) {
      this.screening.title = registration.ScreeningInvitation.title;
    }
    if (registration.ScreeningInvitation && registration.ScreeningInvitation.isBlind) {
      this.screening.title = "Movie Preview";
    }
    this.titleService.setTitle('Confirmation - ' + this.screening.title);
    this.screeningAddress = this.makeScreeningAddress();
    this.isOnlineEvent = this.screening.Venue && this.screening.Venue.isOnline;
    this.bypassNDA = this.isOnlineEvent && this.screening.bypassNDA;
    if (registration.externalRegistration) {
      if (['Approved', 'Waitlist'].includes(registration.externalRegistration.state)) {
        this.onlineVenueLinkLabel = registration.externalRegistration.state === 'Approved' ? 'Ready to Attend' : 'On Event Waitlist'
      } else if (['Qualified', 'Registered', 'No Show'].includes(registration.externalRegistration.state)) {
        this.onlineVenueLink = registration.redirectUrl
        if (registration.externalRegistration.state === 'Registered') {
          this.onlineVenueLinkLabel = registration.externalRegistration.selfGuided ? 'Start Tech Check' : 'Tech Check Scheduled'
        } else {
          this.onlineVenueLinkLabel = 'Schedule Tech Check'
        }
      }
    }
    this.mapLink = 'http://maps.google.com/?q=' + this.screeningAddress;
    this.account = registration.Account;
  }

  private makeScreeningAddress(): string {
    if (!this.screening || !this.screening.Venue) {
      this.screening.Venue = {};
      return '';
    }
    return this.screening.Venue.isOnline ? '' : this.sharedService.getScreeningAddress(this.screening);
  }

  public getOptionalProperty(object: any, key: string) {
    if (object.hasOwnProperty(key)) {
      //tslint:disable-next-line
      return object[key];
    } else {
      return;
    }
  }
}
