import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CheckboxInput } from '../../form-controls/checkbox-input';

@Component({
  selector: 'industry-form',
  styleUrls: ['./industry-form.component.scss'],
  templateUrl: './industry-form.component.pug'
})
export class IndustryForm implements OnInit {
  @Input() public question: any;
  @Input() public showHighlightedError: boolean;
  // @Input() public form: FormGroup;
  @ViewChildren(CheckboxInput) public checkboxes: QueryList<CheckboxInput>;

  public options: string[];
  private validAnswers: string[];
  private noneLabel: string;
  private isValid: boolean;
  public empty: boolean = true;

  private shuffleArray(original: any[], end?) {
    const { array, sticky } = original.reduce((p, c) => {
      if (c === end) {
        p.sticky.push(c)
      } else {
        p.array.push(c)
      }
      return p
    }, { array: [], sticky: [] })
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return [...array, ...sticky]
  }

  public ngOnInit() {
    if (this.question) {
      const industryMap = this.question.configJSON.industry || []
      this.validAnswers = this.question.configJSON.answers.filter((answer, i) => !industryMap[i]);
      const answers = this.question.configJSON.answers
      this.options = this.question.configJSON.randomize ? this.shuffleArray(answers, this.question.configJSON.exclusiveIndex) : answers
      let noneIndex = ![null, undefined].includes(this.question.configJSON.exclusiveIndex) ? (this.question.configJSON.randomize ? this.options.length - 1 : parseInt(this.question.configJSON.exclusiveIndex)) : null
      if (Number.isInteger(noneIndex)) {
        this.noneLabel = this.options[noneIndex]
      }
    }
  }

  public onItemChecked($event) {
    this.isValid = $event.value === true && this.validAnswers.includes($event.label)
    if ($event.value === true) {
      this.checkboxes.forEach((checkbox: any) => {
        if (this.noneLabel === $event.label ? this.noneLabel === checkbox.label : this.noneLabel !== checkbox.label) { return; }
        checkbox.value = false;
      });
    }
  }

  public valid() {
    return this.isValid;
  }

  public isEmpty() {
    if (this.checkboxes) {
      this.empty = !this.checkboxes.reduce(
        (a: any, b: any) => {
          return ([null, undefined].includes(a.value) ? a : a.value) || (b.value ? b.value : false)
        }, { value: false })
    }
    return this.empty;
  }
}
