import { BaseModel, ICommonParams, PaginationParams } from './base';
import { QuestionExpanded } from './question';
import { ScreeningInvitation } from './screeningInvitation';
import { Venue } from './venue';
import { NDA } from './nda';
import { RuleSet } from './ruleSet';

type ScreeningStatus = 'Active' | 'Full' | 'Archived' | 'Cancelled';
type ScreeningLanguage = 'English' | 'Spanish';

enum ScreeningDateFilterType {
  None,
  Upcoming,
  Past
}

interface EventType {
  id: number
  name: string
}

interface ScreeningConfig extends Object {
  children?: {
    max?: number,
    min?: number
  };
  guests?: {
    max?: number,
    min?: number
  };
  snapshot?: {
    frequency?: number,
    linkedFrequency?: number,
    recipients?: Array<any>
  };
}

interface IScreeningsSearchParams extends PaginationParams, ICommonParams {
  excludePast?: boolean;
  searchTerm?: string;
  title?: string;
  venueId?: number;
  clientId?: number;
  eventTypeId?: number;
  digitalEventTypeId?: number;
  status?: ScreeningStatus;
  screeningStartDate?: Date;
  screeningEndDate?: Date;
  inviteClosureStartDate?: Date;
  inviteClosureEndDate?: Date;
}

interface ScreeningData {
  movieId?: number;
  venueId?: number;
  clientId?: number;
  eventTypeId?: number;
  digitalEventTypeId?: number;
  isHighSecurity?: boolean;
  requireVerifiedEmail?: boolean;
  isFamilyEvent?: boolean;
  title?: string;
  description?: string;
  password?: string;
  ticketPageMessage?: string;
  registrationEndDate?: Date;
  screeningStartDate?: Date;
  reminderSendDate?: Date;
  reminderSent?: boolean;
  reminderSubject?: string;
  reminderText?: string;
  rsvpMax?: number;
  waitlistMax?: number;
  hasWaitlist?: boolean;
  bannerMediaId?: number;
  bannerImageURL?: string;
  posterMediaId?: number;
  posterImageURL?: string;
  youtubeURL?: string;
  websiteURL?: string;
  language?: ScreeningLanguage;
  internalNotes?: string;
  seasiJobCode?: string;
  minGuests?: number;
  maxGuests?: number;
  maxChildren?: number;
  minChildren?: number;
  html?: string;
  configJSON?: ScreeningConfig;
  allowLocationInInvite?: boolean;
  seatMax?: number;
  prioritySeatGoal?: number;
  focusGroupGoal?: number;
  includeConfirmationNDA?: boolean;
  includeCOVIDNotice?: boolean;
  bypassNDA?: boolean;
  linkedScreeningIds?: number[];
  minorNdaAssigningAge?:number;
  minorNdaSigningAge?:number;
}

interface Screening extends ScreeningData, BaseModel {
  status?: ScreeningStatus;
  invitedCount?: number;
  waitlistCount?: number;
  preapprovedCount?: number;
  confirmedCount?: number;
  deniedCount?: number;
  cancelledCount?: number;
  seatedCount?: number;
}

interface ScreeningExpanded extends Screening {
  Questions?: QuestionExpanded[];
  Venue?: Venue;
  EventType?: EventType;
  ScreeningInvitation?: ScreeningInvitation;
  NDAs?: NDA[];
  LinkedScreenings?: Screening[];
  RuleSets?: RuleSet[];
}

export {
  Screening, ScreeningData, ScreeningDateFilterType,
  IScreeningsSearchParams, ScreeningStatus, ScreeningExpanded, ScreeningLanguage
}
