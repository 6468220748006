import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'faq-page',
  styleUrls: ['./faq-page.component.scss'],
  templateUrl: './faq-page.component.pug'
})
export class FAQPage implements OnInit {
  public openedFaqIndex = -1;
  public faqs = [
    {
      question: 'Can I bring someone over or under the age group listed on my invitation?',
      answer: `Unfortunately, only guests within the listed age range on your invitation may attend.`
    },
    {
      question: 'Am I allowed to change my guest after I’ve confirmed for a screening?',
      answer: `Yes, as long as your new guest is in the stated age range you can update
       your confirmation by logging into your Preview Free Movies account.
       You simply need to bring them to the event along with your invitation and printed confirmation.`
    },
    {
      question: 'What do I do if I confirmed but never received my email confirmation?',
      answer: `Please check your junk mail folder to ensure that your confirmation was not sent there by accident.
       You can also log into your Preview Free Movies account and under “My Screenings” to resend your confirmation.`
    },
    {
      question: 'I don’t have a printer to print my confirmation, can I still attend?',
      answer: `Yes, if you do not have a printer, you can show your confirmation on your phone at check in.
       Please note after checking in you will need to return any device with a camera
       (including smart phones) back to your car.`
    },
    {
      question: 'If I am waitlisted, how do I know if new seats become available for the event?',
      answer: `If new seats become available, we will email you a new invitation to the
      email address you initially registered with.`
    },
    {
      question: 'Does my confirmation mean I’m guaranteed a seat?',
      answer: `Please keep in mind that no seats are guaranteed, but you will have the best chance of
      getting seated if you arrive at least one hour before show time.`
    },
    {
      question: 'My information has changed since registering, how can I update my profile?',
      answer: `To update your personal information please log into your Preview Free Movies account and under
      “My Account.”`
    },
    {
      question: 'Are cell phones allowed into the theater?',
      answer: `Unfortunately any device with a camera or lens, including smart phones or tablets, are not permitted into the screening.
      If you need your device to show your confirmation, you’ll have an opportunity to return it to your vehicle or check it with security after you’ve checked-in for the event.
      Courtesy camera phone check is available at most events but is not guaranteed. Please note that Preview Free Movies does not assume any responsibility for devices that may be lost or damaged while being held.`
    },
    {
      question: 'How many guests am I allowed to bring based on the confirmation page?',
      answer: `The number of guests allowed varies from event to event.
      The confirmation page is good for you and all the guests you sign up with at the time of confirmation.`
    },
    // {
    //   question: 'How do I provide feedback about my experience?',
    //   answer: `For any questions or concerns about:
    //   <ul>
    //     <li>An event, you may reach us at: <a href="mailto:inquiries&#64;previewfreemovies.com">inquiries&#64;previewfreemovies.com</a></li>
    //     <li>An event reward, you may reach us at: <a href="mailto:rewards&#64;previewfreemovies.com">rewards&#64;previewfreemovies.com</a></li>
    //     <li>Your data or data privacy, you may reach us at: <a href="mailto:dataprivacy&#64;previewfreemovies.com">dataprivacy&#64;previewfreemovies.com</a> or leave a message at (888) 502-3665</li>
    //   </ul>`
    // }
  ];

  constructor(private titleService: Title) { }

  public ngOnInit() {
    this.titleService.setTitle('Preview Free Movies - FAQ');
  }

  public onOpenFaq(index: number) {
    if (index === this.openedFaqIndex && this.openedFaqIndex !== -1) {
      this.openedFaqIndex = -1;
    } else {
      this.openedFaqIndex = index;
    }
  }
}
