import { Answer } from './answer';
import { BaseModel } from './base';

enum QuestionIncludeType {
  None = 0, RequiredQuestions, OptionalQuestions
}

interface QuestionData {
  questionTemplateId?: number;
  screeningId?: number;
  displayOrder?: number;
  fieldCode?: string;
  isSelected?: boolean;
  isDefault?: boolean;
  isRequired?: boolean;
  isForAttendee?: boolean;
  isForGuest?: boolean;
  isForChild?: boolean;
  isForPriorityAttendee?: boolean;
  isForPriorityGuest?: boolean;
  isShowInDci?: boolean;
  isHideInBalancer?: boolean;
  isEditable?: boolean;
  configJSON?: any;
  label?: string;
  displayQuestion?: string;
  required?: boolean; // front-end only
  hidden?: boolean; // front-end only
  formControlName?: string; // front-end only
}

interface Question extends QuestionData, BaseModel { }

interface QuestionExpanded extends Question {
  Answer?: Answer;
}

export { Question, QuestionData, QuestionExpanded, QuestionIncludeType };

