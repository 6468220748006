/* tslint:disable */
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { QrCodeModule } from 'ng-qrcode';
import { ClipboardDirective } from './common/clipboard.directive';

import { AppComponent } from './app.component';
import { Routing } from './app.routes';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {
  AccountService, CompatibilityService,
  NDAService,
  NetworkService,
  QuestionTemplateService,
  RegistrationService,
  ScreeningInvitationService,
  ScreeningService,
  SettingService, SharedService
} from '../services';
import { LoggedInGuard } from './common/guards/logged-in.guard';
import { LoggedOutGuard } from './common/guards/logged-out.guard';
import { PendingChangesGuard } from './common/guards/pending-changes.guard';
import { PrivateInviteGuard } from './common/guards/private-invite.guard';
import { ScreeningRegistrationGuard } from './common/guards/screening-registration.guard';
import { HTMLToPlainTextPipe } from './common/htmlToPlainText.pipe';
import { MomentPipe } from './common/moment.pipe';
import { PasswordStrengthBar } from './common/passwordStrengthBar';
import { SafeHtmlPipe } from './common/safeHtml.pipe';
import {
  AudienceAgreement, CheckboxInput,
  Checkgroup,
  DatepickerField, DropdownPicker, FloatingLabelInput,
  ForgotPasswordModal,
  GridQuestion,
  LoginModal,
  OktaModal,
  PfmFooter, PfmHeader,
  PriorityFullModal,
  Question,
  RadioInput,
  SelectTimeModal,
  TaggedInput,
  WaitlistModal
} from './components';
import { CookieLawModule } from './components/cookie-law';
import { CovidWaiverForm, GuestForm, IndustryForm, KidForm, NdaForm, OnlineVenueForm, QuestionTemplateForm, RegistrationForm, ScreeningQuestionForm, SubscriptionForm, TermsConditionsForm } from './components/forms';
import {
  ConfirmAttendancePage,
  CookiesPolicyPage,
  DoNotSellPage, EmailConfirmationPage, FAQPage, HomePage,
  NdaCompletePage,
  NdaPage, NdaSignPage,
  NotFoundPage, PreCheckConfirmationPage,
  PreCheckNDAPage,
  PrivacyPolicyPage,
  RegistrationErrorPage, RegistrationOptionalPage, RegistrationPage, ResetPasswordPage, ScreeningConfirmationPage, ScreeningInvitationPage, SiteMaintenancePage,
  TermsConditionsPage,
  UpdateProfilePage, UserScreeningsPage, VerificationPage, EmailVerificationFailedPage
} from './components/pages';
import { UserScreeningsTable } from './components/user-screenings-table';

@NgModule({
  imports: [
    CookieLawModule,
    BrowserModule,
    BrowserAnimationsModule,
    PdfJsViewerModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    Routing,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxMaskDirective,
    NgxMaskPipe
    // QrCodeModule,
    // TextMaskModule
  ],
  declarations: [
    AppComponent,
    AudienceAgreement,
    CheckboxInput,
    RadioInput,
    ClipboardDirective,
    PasswordStrengthBar,
    DatepickerField,
    DropdownPicker,
    GridQuestion,
    FloatingLabelInput,
    TaggedInput,
    Checkgroup,
    ForgotPasswordModal,
    OktaModal,
    LoginModal,
    WaitlistModal,
    SelectTimeModal,
    PriorityFullModal,
    PfmFooter,
    PfmHeader,
    // CookieLaw,
    Question,
    GuestForm,
    KidForm,
    IndustryForm,
    CovidWaiverForm,
    OnlineVenueForm,
    NdaForm,
    SiteMaintenancePage,
    SubscriptionForm,
    QuestionTemplateForm,
    ScreeningQuestionForm,
    RegistrationForm,
    TermsConditionsForm,
    HomePage,
    DoNotSellPage,
    ConfirmAttendancePage,
    EmailConfirmationPage,
    FAQPage,
    MomentPipe,
    HTMLToPlainTextPipe,
    SafeHtmlPipe,
    NdaPage,
    NdaSignPage,
    NdaCompletePage,
    NotFoundPage,
    PreCheckConfirmationPage,
    PreCheckNDAPage,
    RegistrationErrorPage,
    RegistrationOptionalPage,
    RegistrationPage,
    ResetPasswordPage,
    ScreeningConfirmationPage,
    ScreeningInvitationPage,
    TermsConditionsPage,
    PrivacyPolicyPage,
    CookiesPolicyPage,
    UpdateProfilePage,
    UserScreeningsPage,
    UserScreeningsTable,
    VerificationPage,
    EmailVerificationFailedPage
  ],
  providers: [
    Title,
    CompatibilityService,
    // ConfigService,
    NetworkService,
    AccountService,
    QuestionTemplateService,
    RegistrationService,
    NDAService,
    ScreeningService,
    SharedService,
    ScreeningInvitationService,
    SettingService,
    LoggedInGuard,
    LoggedOutGuard,
    ScreeningRegistrationGuard,
    PendingChangesGuard,
    PrivateInviteGuard,
    CookieService,
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
