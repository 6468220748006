import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { PfmHeader } from '../../pfm-header';

@Component({
  selector: 'verification-page',
  styleUrls: ['./verification-page.component.scss'],
  templateUrl: './verification-page.component.pug'
})
export class VerificationPage implements OnInit {
  @ViewChild(PfmHeader, { static: false }) public pfmHeader: PfmHeader;
  public isValid: boolean;

  constructor(private titleService: Title, private route: ActivatedRoute) { }

  public ngOnInit() {
    const ERROR_KEY = 'error';
    this.isValid = !this.route.snapshot.data[ERROR_KEY];

    this.titleService.setTitle('Preview Free Movies - Registration Successful');
  }

  public onLoginClicked() {
    this.pfmHeader.presentLogInModal();
  }
}
