import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { PfmHeader } from '../../pfm-header';

@Component({
  selector: 'site-maintenance-page',
  styleUrls: ['./site-maintenance-page.component.scss'],
  templateUrl: './site-maintenance-page.component.pug'
})
export class SiteMaintenancePage implements OnInit {
  @ViewChild(PfmHeader, { static: false }) public pfmHeader: PfmHeader;
  public isValid: boolean;

  constructor(private titleService: Title, private route: ActivatedRoute) { }

  public ngOnInit() {
    // const ERROR_KEY = 'error';
    // this.isValid = !this.route.snapshot.data[ERROR_KEY];

    this.titleService.setTitle('Preview Free Movies');
  }

  // public onLoginClicked() {
  //   this.pfmHeader.presentLogInModal();
  // }
}
