import { Component, QueryList, ViewChildren } from '@angular/core';
import { RadioInput } from '../../form-controls';

@Component({
  selector: 'covid-waiver-form',
  styleUrls: ['./covid-waiver-form.component.scss'],
  templateUrl: './covid-waiver-form.component.pug'
})
export class CovidWaiverForm {
  @ViewChildren(RadioInput) public radios: QueryList<RadioInput>;
  private empty: boolean = true;

  public noticeText: string = 'The CDC lists the current symptoms of COVID-19 as including: fever or chills, cough, shortness of breath or difficulty breathing, fatigue, muscle or body aches, headache, New loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting and diarrhea.'
  public questions: any[] = [
    {
      questionText: 'Have you experienced any of these symptoms or knowingly been exposed to anyone experiencing these symptoms or COVID-19 in the last 14 days?',
      questionGroup: 'COVID_Symptoms',
      questionOptions: [
        {
          label: 'YES',
          answer: 'INVALID',
        },
        {
          label: 'NO',
          answer: 'VALID',
        }
      ]
    },
    {
      questionText: 'Do you agree that if you start to develop any COVID-19 symptoms or have any exposure to anyone with or displaying symptoms for COVID-19, between now and this event, that you will no longer attend?',
      questionGroup: 'COVID_Agreement',
      questionOptions: [
        {
          label: 'YES',
          answer: 'VALID',
        },
        {
          label: 'NO',
          answer: 'INVALID',
        }
      ]
    },
    {
      questionText: 'Preview Free Movies is adhering to the recommended guidelines from the CDC, and State & Local governmental agencies. By confirming for this event, you acknowledge the contagious nature of COVID- 19, and that you are not experiencing any COVID-19 related symptoms and voluntarily assume the inherent risk by attending this event.',
      questionGroup: 'COVID_Risk',
      questionOptions: [
        {
          label: 'I AGREE',
          answer: 'VALID',
        },
        {
          label: 'I DO NOT AGREE',
          answer: 'INVALID',
        }
      ]
    }
  ]

  public value: any = {
    'COVID_Symptoms': null,
    'COVID_Agreement': null,
    'COVID_Risk': null,
  }

  public values() {
    return this.value;
  }

  public isInvalid() {
    return Object.values(this.value).includes('INVALID')
  }

  public isEmpty() {
    return Object.values(this.value).includes(null)
  }

  public onItemChecked(item: any) {
    this.value[item.option] = item.answer;
  }

}
