import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { CanComponentDeactivate } from '../../../common/guards/pending-changes.guard';

import { AccountService, NDAService, RegistrationService, ScreeningService, SharedService } from '../../../../services';

import { RegistrationExpanded, ScreeningExpanded, ScreeningInvitation } from '../../../../../../common/interfaces';

import moment from 'moment';
import 'moment-timezone';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { NdaForm } from '../../forms';

@Component({
  selector: 'nda-sign-page',
  styleUrls: ['./nda-sign-page.component.scss'],
  templateUrl: './nda-sign-page.component.pug'
})
export class NdaSignPage implements OnInit, CanComponentDeactivate {

  @ViewChild(NdaForm, { static: false }) public ndaForm: NdaForm;

  public submitted: boolean;
  public screeningAddress: string = '';
  public screeningLocation: string = '';
  public registration: RegistrationExpanded;
  public screening: ScreeningExpanded;
  private accessTokenId: string;
  // private encryptedId: string;
  public form: FormGroup;
  public loading: boolean = true;
  public waiting: boolean = false;
  public showServerError: boolean = false;
  public errorMsg: string;
  public preview: boolean = false;
  public ndaLabel: string = '';
  public userTimezone: string;
  public checkInTime: any;
  public isOnlineVenue: boolean = false;

  constructor(public screeningService: ScreeningService, private registrationService: RegistrationService, private ndaService: NDAService, private route: ActivatedRoute,
    private router: Router, private sharedService: SharedService, private titleService: Title, public accountService: AccountService,
    private formBuilder: FormBuilder, private cookieService: CookieService) { }

  public ngOnInit() {
    const TOKEN_KEY = 'access_token';
    let accessTokenId = this.route.snapshot.queryParams[TOKEN_KEY];
    if (accessTokenId) {
      this.accessTokenId = accessTokenId;
    } else {
      this.accessTokenId = this.cookieService.get('accessToken');
    }
    this.titleService.setTitle('Sign NDA');
    let id = this.route.snapshot.params['id'];
    let ndaId = this.route.snapshot.params['ndaId'];
    this.preview = this.route.snapshot.data['preview'];
    this.userTimezone = moment.tz.guess();
    // this.encryptedId = id;
    let getData: Observable<string>;
    if (!!ndaId && !!this.preview) {
      getData = this.ndaService.getById(ndaId).pipe(
        tap(data => {
          this.ndaLabel = data.label
          this.registration = {
            Account: {
              firstName: 'FIRSTNAME',
              lastName: 'LASTNAME',
              email: 'EMAIL@EMAIL.COM',
              phoneNumber: '(XXX) XXX-XXXX',
              instagram: 'INSTAGRAM_HANDLE',
              twitter: 'TWITTER_HANDLE'
            },
            Screening: {
              NDAs: [data]
            },
            ndaSent: true
          };
        }),
        map(data => null)
      )
    } else {
      getData = this.registrationService.getByIdNda(id, accessTokenId).pipe(
        map(data => {
          this.registration = data;
          if (moment() >= moment.utc(data.Screening.screeningStartDate)) {
            return 'past'
          } else if (!data.ndaSent) {
            return 'none'
          } else if (data.ndaSent && data.ndaSignature) {
            return 'signed'
          } else {
            // improve by moving to tap()
            this.screening = data.Screening;
            if (data.ScreeningInvitation) {
              this.screening.title = data.ScreeningInvitation.isBlind ? 'Movie Preview' : data.ScreeningInvitation.title
            }
            this.setScreeningData(this.screening, data.ScreeningInvitation);
            return null
          }
        })
      )
    }
    let redirectUrl;
    getData.subscribe(resp => {
      if (resp === 'past') {
        this.sharedService.setRegistrationError({
          errorTitle: 'Screening Date Past',
          pageTitle: 'NDA Error',
          message: 'Sorry, but this event has past.'
        });
        this.router.navigate(['registration-error']);
      } else if (resp === 'signed') {
        if (this.registration.redirectUrl) {
          redirectUrl = this.registration.redirectUrl
        } else {
          this.router.navigate(['/nda-complete']);
        }
      } else if (resp === 'none') {
        this.router.navigate(['/not-found']);
      } else {
        console.log('here we are', this.registration)
      }
    }, err => {
      console.log(err)
      // this.router.navigate(['/not-found']);
      this.sharedService.setRegistrationError({
        errorTitle: 'NDA Expired',
        pageTitle: 'NDA Expired',
        message: 'Unfortunately, this agreement has expired. You will still be able to complete your check-in on-site at the event.'
      });
      this.router.navigate(['registration-error']);
    }, () => {
      if (redirectUrl) {
        window.location.href = redirectUrl
      } else {
        this.loading = false;
      }
    })
    this.form = this.formBuilder.group({});
  }


  private setScreeningData(screening: ScreeningExpanded, ScreeningInvitation: ScreeningInvitation) {
    this.sharedService.setScreeningRegistration(this.registration);
    this.titleService.setTitle('Pre Check In - ' + screening.title);
    this.screeningAddress = this.sharedService.getScreeningAddress(screening);
    this.screeningLocation = this.sharedService.getScreeningLocation(screening);
    this.isOnlineVenue = screening.Venue && screening.Venue.isOnline;
    const checkInTimeArr:any[] = this.isOnlineVenue && screening.EventType && screening.EventType.name !== 'Online Focus Group' && ScreeningInvitation.checkInTime1 ? ScreeningInvitation.checkInTime1.split(':') : [];
    this.checkInTime = checkInTimeArr.length > 1 ? moment(screening.screeningStartDate).set('hour',checkInTimeArr[0]).set('minute', checkInTimeArr[1]) : screening.screeningStartDate;
  }

  private dataURIToBlob(dataURI) {
    const parts = dataURI.split(';base64,');
    const contentType = parts[0].split(":")[1];
    const raw = atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  public onFormSubmitted(isSigned: boolean, signatureData: any, customFields: any) {
    this.showServerError = false;
    if (!isSigned) {
      alert("Please sign the NDA to continue.")
    } else if (!Object.values(customFields).reduce((p, c) => !!p && !!c, true)) {
      alert("Please complete all fields continue.")
    } else {
      this.waiting = true;
      let fileName = uuid();
      let url;
      const codedFile = this.dataURIToBlob(signatureData)
      // this.registrationService.
      this.registrationService.getSignedUploadRequest(codedFile, fileName, this.accessTokenId).pipe(
        tap((response: any) => {
          url = response.url;
        }),
        switchMap((response: any) => {
          let reqUrl = response.signedRequest;
          return this.registrationService.uploadFile(reqUrl, codedFile)
        })
      ).subscribe((result: any) => {
        this.registrationService.ndaUpdate(this.registration.id, url, customFields, this.accessTokenId).subscribe((updated) => {
          console.log(updated)
          if (updated.redirectUrl) {
            window.location.href = updated.redirectUrl
          } else {
            this.router.navigate(['/nda-complete']);
          }
        }, (err) => {
          console.log(err)
          this.showServerError = true
        })
      }, (error) => {
        console.log(error)
        this.showServerError = true
      })
    }

  }
}
