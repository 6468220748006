import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldValidator } from '../../../common/validator';

@Component({
  selector: 'tagged-input',
  styleUrls: ['./tagged-input.component.scss'],
  templateUrl: './tagged-input.component.pug',
  // providers: [ {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => TaggedInput),
  //     multi: true
  //   }]
})
export class TaggedInput implements OnInit {//}, ControlValueAccessor {
  @ViewChild('tagInput', { static: false }) tagInputRef: ElementRef;
  @Input() public value: string[];
  @Output() public valueChange = new EventEmitter<any>();
  @Input() public placeholder: string;
  @Input() public inputType: string;
  public form: FormGroup;
  // public tags: string[];

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      tag: [undefined],
    });
  }

  public focusTagInput() {
    this.tagInputRef.nativeElement.focus();
  }

  public onKeyUp(event: KeyboardEvent) {
    const inputValue: string = this.form.controls['tag'].value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Space') {
        let value = inputValue;
        if (inputValue.slice(-1) === ',') {
          value = inputValue.slice(0, -1)
        }
        this.addTag(value);
        // this.form.controls.tag.setValue('');
      }
    }
  }

  public addTag(tag: string) {
    let isValid = false;
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (this.inputType === "email") {
      isValid = FieldValidator.validEmail(tag)
    } else {
      isValid = true
    }
    if (tag.length > 0 && !this.value.includes(tag) && isValid) {
      this.value.push(tag);
      this.valueChange.emit(this.value)
      this.form.controls['tag'].setValue('');
    }
  }

  public removeTag(tag?: string) {
    if (!!tag) {
      this.value = this.value.filter(x => x !== tag);
    } else {
      this.value.splice(-1);
    }
    this.valueChange.emit(this.value)
  }

}
