import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { SharedService } from '../../..';
import { Answer, Guest, GuestType, KidExpanded, Question, QuestionDataType, QuestionTemplateId } from '../../../../../../common/interfaces';
import { FieldValidator } from '../../../common/validator';

@Component({
  selector: 'guest-form',
  styleUrls: ['./guest-form.component.scss'],
  templateUrl: './guest-form.component.pug'
})
export class GuestForm implements OnInit {
  @Input() public guestNumber: number;
  @Input() public guestLabel: string;
  @Input() public isFamilyMode: boolean;
  @Input() public isChildren: boolean;
  @Input() public guest: Guest;
  @Input() public child: KidExpanded;
  @Input() public questions: Array<Question>;
  @Input() public isEditing = false;
  @Input() public countryId: number; // THIS IS NOT SET IN THE PARENT COMPONENT
  @Input() public showHighlightedError: boolean;
  @Output() public guestValidationStateChanged = new EventEmitter<boolean>();
  @Output() public onAskIfChangedParent: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public guests: Array<any>;
  public options: Array<any>;
  public firstNameControl = new FormControl('', FieldValidator.validateName);
  public lastNameControl = new FormControl('', FieldValidator.validateName);
  public phoneTypeControl = new FormControl();
  public guestQuestions: Array<Question>;
  public guestAnswers: any = {};
  private isValid: boolean;
  public formControlSuffix: string;
  public guestType: string = '';
  // kids: KidExpanded[];

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService
  ) { }

  public ngOnInit() {
    this.guestQuestions = this.questions.map(x => structuredClone(x));
    this.form = this.formBuilder.group({});
    let guestType = this.isFamilyMode ? 'Child ' : 'Guest ';
    if (this.isChildren) {
      guestType = 'Child ';
    }
    this.guestType = guestType;
    this.guestLabel = guestType + this.guestNumber + ' ';
    if (!this.guest) { this.guest = {}; }
    this.formControlSuffix = `${this.guestNumber}${this.isFamilyMode}`;
    // this.kids = this.sharedService.getKids()
    this.setUpForm();
  }

  public valid() {
    return this.isValid;
  }

  public values() {
    let guest: any = this.guest;
    guest.Answers = guest.Answer ? guest.Answer : [];
    // if(this.isFamilyMode) {
    let type: GuestType = this.isFamilyMode ? 'Child' : 'Guest';
    guest.type = type;
    // }
    if (this.guestQuestions) {
      this.guestQuestions.forEach(question => {
        let control = this.form.controls[question.formControlName];
        if (!control.pristine) {
          // get answer data
          let existing = guest.Answers.findIndex(ans => this.guestAnswers[question.id] && ans.id === this.guestAnswers[question.id].id)
          let values = [control.value]
          if (question.configJSON && question.configJSON.dataType === QuestionDataType.CheckGroup) {
            // make an array of selected values
            let obj = Object.keys(control.value).reduce((p, c) => {
              if (control.value[c] === true) p[c] = true;
              return p;
            }, {});
            values = Object.entries(obj);
          }
          if (existing !== -1) {
            let answer: Answer = {
              ...guest.Answer[existing],
              questionId: question.id,
              questionTemplateId: question.questionTemplateId,
              answerJSON: { values, label: question.label }
            }
            guest.Answer[existing] = answer
          } else {
            let answer: Answer = {
              questionId: question.id,
              questionTemplateId: question.questionTemplateId,
              answerJSON: { values, label: question.label }
            };
            guest.Answers.push(answer);
          }

          // update gender/ethnicity/age properties
          if (question.questionTemplateId === QuestionTemplateId.Ethnicity || question.questionTemplateId === QuestionTemplateId.ChildEthnicity) {
            guest.ethnicity = control.value;
          } else if (question.questionTemplateId === QuestionTemplateId.Gender || question.questionTemplateId === QuestionTemplateId.ChildGender) {
            guest.gender = control.value;
          } else if (question.questionTemplateId === QuestionTemplateId.Age ||
            question.questionTemplateId === QuestionTemplateId.ChildAge) {
            guest.ageGroup = control.value;
          }
        }
      });
    }
    return guest;
  }

  // show/hide askIf questions based on parent answer value
  public onAskIfChanged($event) {
    let parentId = $event.questionId;
    let newAnswer = $event.answer;
    let dataType = $event.dataType;
    this.guestQuestions.forEach((linkedQuestion) => {
      const display = (linkedQuestion.configJSON.displayOptions || [])
      if (!!display.find(config => config.askIfId === parentId)) {
        let willShow = false;
        const show = display.map((config) => {
          let shouldShow;
          let answer = config.askIfId === parentId ? newAnswer : this.form.controls[config.askIfId] ? this.form.controls[config.askIfId].value : null;
          if (answer) {
            switch (config.askIfOperator) {
              case 'IS':
                if (dataType !== 1) {
                  shouldShow = answer === config.askIfValue;
                } else {
                  shouldShow = answer.toLowerCase() === config.askIfValue.toLowerCase();
                }
                break;
              case 'ISN\'T':
                if (dataType !== 1) {
                  shouldShow = answer !== config.askIfValue;
                } else {
                  shouldShow = answer.toLowerCase() !== config.askIfValue.toLowerCase();
                }
                break;
              case 'INCLUDES':
              case 'EXCLUDES':
                if (dataType !== 1) {
                  shouldShow = (config.askIfOption ? answer && answer[config.askIfOption] === config.askIfValue : answer && answer[config.askIfValue]) === !!(config.askIfOperator === 'INCLUDES');
                } else {
                  let found = answer.toLowerCase().search(config.askIfValue.toLowerCase()) > -1;
                  shouldShow = (found && config.askIfOperator === 'INCLUDES')
                    || (!found && config.askIfOperator !== 'INCLUDES');
                }
                break;
              case 'COUNT GREATER THAN':
              case 'COUNT LESS THAN':
                let values = answer.filter((value) => {
                  return value === true;
                });
                if (config.askIfOperator === 'COUNT GREATER THAN') {
                  shouldShow = values.length > config.askIfValue;
                } else {
                  shouldShow = values.length < config.askIfValue;
                }
              default:
                break;
            }
          }
          return shouldShow
        })
        if (linkedQuestion.configJSON.displayOperator === "CUSTOM") {
          const condition = linkedQuestion.configJSON.customLogic || ""
          const accumulator = {
            level: 0,
            operator: [],
            not: [],
            value: [],
            match: show
          }
          try {
            const result: any = Array.from(condition.replace(/\s/g, '')).reduce((s: any, char, i, arr) => {
              switch (char) {
                case "&":
                case "|":
                  s.operator[s.level] = char
                  break;
                case "!":
                  s.not[s.level] = true
                  break;
                case "(":
                  s.level++;
                  break;
                case ")":
                  s.level--;
                case "$":
                  let val = char === "$" ? s.match[parseInt(arr.join('').slice(i).match(/^[^\d]*(\d+)/)[1]) - 1] : s.value.pop()
                  if (s.not[s.level]) {
                    val = !val
                    s.not[s.level] = false
                  }
                  if (typeof s.value[s.level] !== 'boolean') {
                    s.value[s.level] = val
                  } else if (s.operator[s.level] === "&") {
                    s.value[s.level] = s.value[s.level] && val
                  } else if (s.operator[s.level] === "|") {
                    s.value[s.level] = s.value[s.level] || val
                  }
                  break;
              }
              return s
            }, accumulator)
            willShow = !!result.value[0]
          } catch (err) {
            console.log("ERROR PARSING CUSTOM LOGIC", err)
          }
        } else {
          willShow = show.reduce((p, c) => (linkedQuestion.configJSON.displayOperator === "ALL" ? p && c : p || c))
        }
        if (!this.form.controls[linkedQuestion.formControlName]) { return; }
        if (linkedQuestion.isRequired) {
          let validators = willShow ? Validators.required : null;
          this.form.controls[linkedQuestion.formControlName].setValidators(validators);
        }
        linkedQuestion.hidden = !willShow;
      }
    });
    this.onAskIfChangedParent.emit($event)
  }

  private setUpForm() {
    let group: any = {};
    let guest: any = this.guest;
    // let guestAnswers = {};
    if (!this.isFamilyMode) {
      if (guest && Object.keys(guest).length) {
        if (Array.isArray(guest.Answer)) {
          guest.Answer.forEach((answer) => {
            if (answer.answerJSON && answer.answerJSON.values[0]) {
              this.guestAnswers[answer.Question.id] = {
                id: answer.id,
                value: answer.answerJSON.values[0]
              }
            }
          })
        }
      }
      // this.guestQuestions = guest.Answer.map(x => x.Question);
      // }
      // } else if (this.questions && !guest) {
      this.guestQuestions.forEach(question => {
        // set empty answer for freeform text questions to prevent 'undefined' in textarea
        let q: any = question;
        if (q.configJSON.dataType === 1) {
          q.answer = q.answer || '';
        }

        q.answer = this.guestAnswers && this.guestAnswers[q.id] ? this.guestAnswers[q.id].value : null

        const formControlName = `${q.id}${this.formControlSuffix}`;
        question.formControlName = formControlName;
        if (!q.isRequired || q.hidden || this.isEditing) {
          group[formControlName] = new FormControl();
        } else {
          group[formControlName] = new FormControl(null, Validators.required);
        }
      });

      group[`firstName${this.formControlSuffix}`] = this.firstNameControl;
      group[`lastName${this.formControlSuffix}`] = this.lastNameControl;
      group[`email${this.formControlSuffix}`] = new FormControl(this.guest.email, FieldValidator.emailFormat);
      group[`phoneNumber${this.formControlSuffix}`] = new FormControl(this.guest.email, FieldValidator.validatePhoneNumber);
      group[`phoneType${this.formControlSuffix}`] = this.phoneTypeControl;
    } else {
      // TODO: Clean this up
      guest = this.child
      if (guest && Object.keys(guest).length) {
        if (Array.isArray(guest.Answer)) {
          guest.Answer.forEach((answer) => {
            if (answer.answerJSON && answer.answerJSON.values[0]) {
              this.guestAnswers[answer.Question.id] = {
                id: answer.id,
                value: answer.answerJSON.values[0]
              }
            }
          })
        }
      }
      // this.guestQuestions = guest.Answer.map(x => x.Question);
      // }
      // } else if (this.questions && !guest) {
      this.guestQuestions.forEach(question => {
        // set empty answer for freeform text questions to prevent 'undefined' in textarea
        let q: any = question;
        if (q.configJSON.dataType === 1) {
          q.answer = q.answer || '';
        }

        if (this.guestAnswers[q.id]) {
          q.answer = this.guestAnswers[q.id].value
        }

        const formControlName = `${q.id}${this.formControlSuffix}`;
        question.formControlName = formControlName;
        if (!q.isRequired || q.hidden || this.isEditing) {
          group[formControlName] = new FormControl();
        } else {
          group[formControlName] = new FormControl(null, Validators.required);
        }
      });
    }
    // }

    this.form = this.formBuilder.group(group);
    this.form.valueChanges.subscribe(data => this.checkValidationState());
  }

  private checkValidationState() {
    if (!this.isValid) {
      this.isValid = true;
      this.guestValidationStateChanged.emit();
    }
    let valid = !this.form || this.form.valid;
    if (this.isValid === undefined || valid !== this.isValid) {
      this.isValid = valid;
      this.guestValidationStateChanged.emit();
    }
  }
}
