import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../../../services';
import { FieldValidator } from '../../../common/validator';

@Component({
  selector: 'do-not-sell-page',
  styleUrls: ['./do-not-sell-page.component.scss'],
  templateUrl: './do-not-sell-page.component.pug'
})
export class DoNotSellPage implements OnInit {
  public showError: any = null;
  public success: any = null;
  public doNotSellForm: FormGroup;

  constructor(private titleService: Title, private route: ActivatedRoute, private accountService: AccountService) { }

  public ngOnInit() {
    this.titleService.setTitle('Preview Free Movies - Do Not Sell My Personal Information');
    this.doNotSellForm = new FormGroup({
      email: new FormControl(null, [Validators.required, FieldValidator.emailFormat]),
      lastName: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
    })
  }

  public addToDoNotSell(values: any) {
    this.accountService.addToDoNotSell(values.email, values.lastName, values.postalCode).subscribe(
      (resp) => {
        this.success = 'Your request has been sucessfully submitted'
        this.showError = null;
        this.doNotSellForm.controls['email'].setValue(null)
        this.doNotSellForm.controls['lastName'].setValue(null)
        this.doNotSellForm.controls['postalCode'].setValue(null)
      },
      (err) => {
        this.success = null;
        this.showError = 'An error has occurred. Please try again.'
        console.log('Error', err)
      }, () => {
        console.log('Done adding to do not sell list')
      }
    );
  }

}
