
export interface StatusError extends Error {
  status?: number;
  statusCode?: number;
  description?: string;
}

interface StatusErrorConstructor extends ErrorConstructor {
  new (message?: string, status?: number, statusCode?: number): StatusError;
  (message?: string, status?: number, statusCode?: number): StatusError;
}

declare const StatusError: StatusErrorConstructor;

export class Errors {
  public static messages = {
    // Account
    ERROR_LOGIN_FAILED: 'Login failed',
    ERROR_EMAIL_INVALID: 'Must provide a valid email',
    ERROR_EMAIL_EXISTS: 'An account with this email address exists already',
    ERROR_REGISTRATION_EXISTS: 'A registration with this email address exists already.',
    ERROR_PASSWORD_NOT_MATCH: 'Passwords do not match.',
    ERROR_PASSWORD_RESET_REQUEST: 'Unable to send an email for reset password.',
    ERROR_ACCOUNT_ACTIVATION: 'Unable to send an email for account activation.',
    ERROR_NEEDS_VERIFICATION: 'Must verify account before logging in',
    ERROR_MEDIA_INVALID_FILETYPE: 'Invalid fileType specified',
    ERROR_POSTAL_CODE_INVALID: 'Invalid postal code.',
    ERROR_COUNTRY_UNKNOWN: 'Uknown country.',

    // Screening
    ERROR_SCREENING_FULL: 'This event is full',
    ERROR_SCREENING_PASSWORD_EXIST: 'A screening with this password already exists',
    ERROR_BLOCKED_DOMAIN: 'Referring domain is blocked from screening registration.',
    ERROR_REGISTRATION_OVER: 'Registration for this event has ended',
    ERROR_MULTIPLE_REGISTRATIONS: 'User has multiple registrations for Movie/Day',
    ERROR_PRIORITY_FULL: 'Priority list is full',
    ERROR_INVALID_REMINDER_DATE: 'The send date for the screening reminder is invalid',
    ERROR_INVALID_RECRUITER_CODE: 'Invalid recruiter code',
    ERROR_SCREENING_VALIDATION: 'Could not confirm. Please check your code and try again.',
    ERROR_CSV_MISSING_EMAIL: 'Invalid CSV data. Please provide a valid email per registration',
    // General
    ERROR_MESSAGE_FORBIDDEN: 'Action not allowed',
    ERROR_UNAUTHORIZED: 'Unauthorized',
    ERROR_NOT_FOUND: 'Not found.'
  };

  public static codes = {
    // General
    ERROR_MESSAGE_FORBIDDEN: 403,
    ERROR_UNAUTHORIZED: 401,
    ERROR_NOT_FOUND: 404,
    ERROR_UNPROCESSABLE_ENTITY: 422
  };

  public static makeError(message: string, statusCode?: number, stack?: string, description?: string) {
    stack = stack || '';
    statusCode = statusCode || 400;

    const code = statusCode;
    const stackString = stack;

    const error: StatusError = {
      name: '',
      message,
      description,
      status: code,
      statusCode: code,
      stack: stackString
    };

    return error;
  }
}
