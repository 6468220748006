import { BaseModel, ICommonParams, PaginationParams } from './base';
import { CampaignMessage } from './campaignMessage';

type CampaignStatus = 'Active' | 'Archived' | 'Cancelled';
type CampaignLanguage = 'English' | 'Spanish';

enum CampaignDateFilterType {
  None,
  Upcoming,
  Past
}

interface ICampaignsSearchParams extends PaginationParams, ICommonParams {
  excludePast?: boolean;
  searchTerm?: string;
  title?: string;
  clientId?: number;
  campaignTypeId?: number;
  status?: CampaignStatus;
  campaignStartDate?: Date;
  campaignEndDate?: Date;
}

interface CampaignData {
  movieId?: number;
  clientId?: number;
  campaignTypeId?: number;
  title?: string;
  description?: string;
  campaignStartDate?: Date;
  campaignEndDate?: Date;
  language?: CampaignLanguage;
  internalNotes?: string;
  seasiJobCode?: string;
}

interface Campaign extends CampaignData, BaseModel {
  status?: CampaignStatus;
}

interface CampaignExpanded extends Campaign {
  CampaignMessages?: CampaignMessage[];
}

export {
  Campaign, CampaignData, CampaignDateFilterType,
  ICampaignsSearchParams, CampaignStatus, CampaignExpanded, CampaignLanguage
}
