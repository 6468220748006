// tslint:disable
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
// tslint:enable

@Component({
    selector: 'nda-complete-page',
    styleUrls: ['./nda-complete-page.component.scss'],
    templateUrl: './nda-complete-page.component.pug'
})
export class NdaCompletePage implements OnInit {
    private error: any;

    constructor(private titleService: Title) { }

    public ngOnInit() {
        // tslint:disable-next-line
        this.titleService.setTitle('NDA Completed');
    }

}
