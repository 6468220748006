import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RegistrationService } from '../../../../services';

@Component({
  selector: 'priority-full-modal',
  styleUrls: ['./priority-full-modal.component.scss'],
  templateUrl: './priority-full-modal.component.pug'
})
export class PriorityFullModal {
  @Output() public onSubmit: EventEmitter<string> = new EventEmitter();
  public hasRegistration: boolean;
  public registrationId: number;
  constructor(private registrationService: RegistrationService, public dialog: BsModalRef) {
    this.hasRegistration = this.registrationId ? true : false;
  }

  public onKeyUp() {
    this.close();
  }

  public onOKClicked() {
    this.close();
  }

  public onYesClicked() {
    this.close(this.hasRegistration ? 'keep' : 'new')
  }

  public onNoClicked() {
    this.close();
  }

  public onCancelClicked() {
    this.registrationService.cancel(this.registrationId).subscribe(
      (registration) => {
        console.log('registration cancelled')
      },
      (err) => {
        console.log('Update registration err - ', err);
        this.close('cancel');
      }
    );
  }

  private close(result?) {
    this.onSubmit.emit(result);
    this.dialog.hide();
  }

}
