import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'floating-label-input',
  styleUrls: ['./floating-label-input.component.scss'],
  templateUrl: './floating-label-input.component.pug',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FloatingLabelInput),
    multi: true
  }]
})
export class FloatingLabelInput implements ControlValueAccessor {
  @Output() public onValueChanged: EventEmitter<string>;
  @Input() public labelText: string;
  @Input() public isInvalid: any;
  @Input() public value: string;
  @Input() public inputType: any = 'text'; // default;
  @Input() public autocomplete: string = 'on';
  public phoneMask = "(000) 000-0000"; //['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public propagateChange = (_: any) => { };

  public onInputChanged(value: string) {
    // trim exta characters from phone number
    if (this.inputType === 'tel' && value && value.length > 14) {
      value = value.substr(0, value.length - 1);
    }
    this.value = value;
    this.propagateChange(value);
  }

  public writeValue(value: any) {
    if (value !== undefined && value !== this.value) {
      this.value = value;
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { };
}
