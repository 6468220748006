import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HTTPMethod, NetworkService } from './network.service';

import { QuestionTemplate } from '../../../common/interfaces';

@Injectable()
class QuestionTemplateService {

  constructor(private networkService: NetworkService) { }

  public get(filterType: number): Observable<Array<QuestionTemplate>> {
    return this.networkService.httpRequest(HTTPMethod.GET, `QuestionTemplates/getRegistrationQuestions`,
      { filterType });
  }
}

export { QuestionTemplateService };
