import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'privacy-policy-page',
  styleUrls: ['./privacy-policy-page.component.scss'],
  templateUrl: './privacy-policy-page.component.pug'
})
export class PrivacyPolicyPage implements OnInit {

  constructor(private titleService: Title, private route: ActivatedRoute) { }

  public ngOnInit() {
    this.titleService.setTitle('Preview Free Movies - Privacy Policy');
  }
}
