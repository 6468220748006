import {
  ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output,
  QueryList, SimpleChanges, ViewChild, ViewChildren
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import {
  AccountExpanded, Answer, Guest, Question, QuestionDataType, QuestionTemplate,
  QuestionTemplateId, QuestionTemplateRegistrationFilter,
  RegistrationData
} from '../../../../../../common/interfaces';
import { QuestionTemplateService } from '../../../../services';
import { GuestForm, IndustryForm } from '../../forms';

@Component({
  selector: 'question-template-form',
  styleUrls: ['./question-template-form.component.scss'],
  templateUrl: './question-template-form.component.pug'
})
export class QuestionTemplateForm implements OnInit, OnChanges {
  @Input() public account: AccountExpanded;
  @Input() public filterType: number;
  @Output() public validationStateChanged = new EventEmitter<boolean>();
  @ViewChild(IndustryForm, { static: false }) public industryForm: IndustryForm;
  @ViewChildren(GuestForm) public guestForms: QueryList<GuestForm>;
  public childOptions: Array<any>;

  public industryQuestion: Question;
  public form: FormGroup;
  public templates: Array<QuestionTemplate>;
  public showChildForms: boolean;
  public childQuestions: Array<QuestionTemplate>;
  public children = Array<Guest>();

  constructor(public formBuilder: FormBuilder, private questionTemplateService: QuestionTemplateService,
    private cdr: ChangeDetectorRef) { }

  public ngOnInit() {
    this.form = this.formBuilder.group({});
    this.showChildForms = this.filterType === QuestionTemplateRegistrationFilter.Optional;
    this.questionTemplateService.get(this.filterType).subscribe(
      templates => this.updateQuestionTemplates(templates),
      err => console.log('QuestionTemplate error - ', err)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    let isDataChanged = false;
    if (changes && changes['account'] && changes['account'].currentValue) {
      if (changes['account'].previousValue) {
        const previousId = changes['account'].previousValue.id;
        const currentId = changes['account'].currentValue.id;
        if (previousId != currentId) {
          isDataChanged = true;
        }
      } else {
        isDataChanged = true;
      }
    }

    //Add if check for filterType changed if required later

    if (isDataChanged) {
      // console.log("Re-Initianlize Question Template Form");
      this.ngOnInit();
    }
  }

  public values() {
    if (!this.account) {
      this.account = {};
    }

    let data: RegistrationData = {
      Answers: [],
      isIndustryValid: !this.industryForm || this.industryForm.valid()
    };
    this.templates.forEach(template => {
      let control = this.form.controls[template.id];

      if (!control.pristine) {
        let values = [];
        if (template.configJSON && template.configJSON.dataType === QuestionDataType.CheckGroup) {
          // checkgroup control values are like { key: value }, but we need to store them as [[key, value]];
          let obj = Object.keys(control.value).reduce((p, c) => {
            if (control.value[c] === true) p[c] = true;
            return p;
          }, {});
          values = Object.entries(obj);
        } else {
          values.push(control.value);
        }
        // get answer data
        let answer: Answer = {
          questionTemplateId: template.id,
          accountId: this.account.id,
          answerJSON: { values, label: template.label }
        };
        data.Answers.push(answer);

        // update gender/ethnicity properties
        if (template.id === QuestionTemplateId.Ethnicity) {
          this.account.ethnicity = control.value;
        } else if (template.id === QuestionTemplateId.Gender) {
          this.account.gender = control.value;
        }
      }
    });

    data.Account = this.account;
    data.Guests = data.Guests || [];
    if (this.guestForms) {
      this.guestForms.map(
        guest => {
          data.Guests.push(guest.values());
        }
      );
    }
    return data;
  }

  public onChildCountChanged(childCount: number) {
    this.children = new Array(childCount).fill('');
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  private updateQuestionTemplates(templates: Array<QuestionTemplate>) {

    let childQuestions = [];
    templates = templates.filter((template: any) => {

      // show fields of employment as a separate component in registrations. Omit from 'My account' page.
      if (template.id === QuestionTemplateId.FieldsOfEmployment) {
        if (this.filterType !== QuestionTemplateRegistrationFilter.None) {
          this.industryQuestion = template;
        }
        return false;
      }

      // child questions to appear if child count is selected
      let config: any = template.configJSON;
      let hasAnswers = config && config.answers && config.answers.length > 0;
      let isValid = hasAnswers || (config && config.dataType === QuestionDataType.Text);
      if (template.label && template.label.split(' ')[0] && template.label.split(' ')[0].includes('Child') && isValid) {
        childQuestions.push(template);
        return false;
      }
      return isValid;
    });

    this.childQuestions = childQuestions;

    if (!templates) { return; }
    let group: any = {};

    templates.forEach(template => {
      let answers = template.Answers || [];
      let answerValue;
      let config = template.configJSON;
      if (config.dataType === QuestionDataType.CheckGroup) {
        // checkbox question answers are stored like: [[key, value], [key, value]]
        // checkgroup component takes an object like: { key: value, key: value }
        if (answers[0] && answers[0].answerJSON && answers[0].answerJSON.values) {
          answerValue = Object.fromEntries(answers[0].answerJSON.values);
        } else {
          answerValue = config.answers.reduce((p, c) => (p[c] = false, p), {});
        }
      } else {
        answerValue = answers.length > 0 && answers[0].answerJSON ? answers[0].answerJSON.values[0] : null;
      }

      template.answer = answerValue;
      template.formControlName = template.id;
      // optional questions. All are optional if on update profile page (QuestionTemplateRegistrationFilter.None)
      if (template.isRegistrationOptional || this.filterType === QuestionTemplateRegistrationFilter.None) {
        if (this.filterType !== QuestionTemplateRegistrationFilter.None) {
          template.displayQuestion += ' (optional)';
        }
        group[template.formControlName] = new FormControl(answerValue);
      } else {
        group[template.formControlName] = new FormControl(answerValue, Validators.required);
      }
    });
    this.templates = templates;
    this.form = this.formBuilder.group(group);
  }

  public valid() {
    return (this.form && this.form.valid) && (this.industryForm ? !this.industryForm.isEmpty() : true);
  }
}
