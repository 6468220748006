import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { AccountService, NDAService, RegistrationService } from '../../../../services';

import { jsPDF } from 'jspdf';
import { combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'nda-page',
  styleUrls: ['./nda-page.component.scss'],
  templateUrl: './nda-page.component.pug'
})
export class NdaPage implements OnInit {

  @ViewChild('container', { static: false }) container: ElementRef;
  // @ViewChild('pdfViewer') public pdfViewer;

  public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  public registration: any;
  public ndas: Array<any> = [];
  public registrantPicture: any;
  public customFields: Array<string>;
  public ndaPdfs: Array<any> = [];

  constructor(private registrationService: RegistrationService, private ndaService: NDAService, private accountService: AccountService, private router: Router, private route: ActivatedRoute) {
    let id: number;
    // const TOKEN_KEY = 'access_token';
    // let accessTokenId = this.route.snapshot.queryParams[TOKEN_KEY];
    // console.log(accessTokenId)
    // let accessToken = this.accountService.getAccessToken()
    // accessTokenId = accessToken.id
    // console.log(accessTokenId)

    this.route.params.forEach((params: Params) => {
      const ID_KEY = 'id';
      id = +params[ID_KEY];
    });
    // this.registrationService.getById(id).subscribe((registration) => {
    const getNda = this.registrationService.getNda(id).pipe(
      tap((registration: any) => {
        console.log('registration', registration)
        this.registration = registration
        if (registration.ndaData && registration.ndaData.ndaUrls && Array.isArray(registration.ndaData.ndaUrls) && registration.ndaData.ndaUrls.length) {
          this.ndas = registration.ndaData.ndaUrls.map((url) => ({ url }));
        } else {
          this.ndas = this.registration.Screening.NDAs || [];
        }
        this.customFields = registration.ndaData ? Object.keys(registration.ndaData.customFields || {}) : []
        this.registrantPicture = registration.registrantPicture || null;
        console.log('ndas', this.ndas)
        console.log('custom fields', this.customFields)
        console.log('loading nda pdfs...', this.ndas)
      }),
      switchMap((registration: any) => {
        const obvs = this.ndas.map((nda) => {
          return this.loadNda(nda)
        })
        // Promise.all(promises).then((pdfs) => {
        //   console.log('pdfs:', pdfs)
        //   this.ndaPdfs = pdfs
        // })
        return combineLatest(...obvs)
      })
    ).subscribe((pdfs) => {
      this.ndaPdfs = pdfs
    }, err => {
      console.log('Error getting registration data: ', err);
      if (err && err.status === 401) {
        this.router.navigate(['/not-found']);
      }
    })

    // this.registrationService.getById(id).flatMap(
    //   registration => {
    //     if (registration.ndaSignature) {
    //       let urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i
    //       if (!urlRegex.test(registration.ndaSignature)) {
    //         registration.ndaSignature = JSON.parse(registration.ndaSignature)
    //         this.registration = registration;
    //         return this.registrationService.getNDAPDF(id)
    //       } else {
    //         this.registration = registration;
    //         return of(null)
    //       }
    //     } else {
    //       return of(null)
    //     }
    //   }).subscribe((resp) => {
    //     // if (resp && resp.id) {
    //     if (resp) {
    //     //   this.registration = resp;
    //     // } else {
    //       this.ndaPdf = `data:application/pdf;base64,${btoa(resp)}`;
    //     }
    //   },
    //   err => {
    //     console.log('Error getting registration data: ', err);
    //     if (err && err.status === 401) {
    //       this.router.navigate(['/not-found']);
    //     }
    //   }
    // );
  }

  public ngOnInit() {
    // remove
  }

  private loadNda(nda: any) {
    return this.ndaService.getPDF(nda.url).pipe(
      map((resp: ArrayBuffer) => {
        const pdfFile = new Blob([resp], { type: 'application/pdf' });
        return URL.createObjectURL(pdfFile);
      })
    )
  }

  public downloadPDF() {
    let doc = new jsPDF();

    let specialElementHandlers = {
      '#editor': (element, renderer) => {
        return true;
      }
    };

    let container = this.container.nativeElement;
    console.log(container);

    // doc.fromHTML(container.innerHTML, 15, 15, {
    //   'width': 190,
    //   'elementHandlers': specialElementHandlers
    // });

    doc.html(document.body, {
      callback: () => {
        doc.save('nda.pdf');
      }
    });

    // doc.save('test.pdf');
  }

}
