import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

import { ScreeningLanguage, SettingType } from '../../../../../common/interfaces';
import { SettingService } from '../../../services';

import * as DOMPurify from 'dompurify';

@Component({
  selector: 'audience-agreement',
  styleUrls: ['./audience-agreement.component.scss'],
  templateUrl: './audience-agreement.component.pug'
})
export class AudienceAgreement implements OnChanges {
  @Input() public language: ScreeningLanguage;
  @ViewChild('dataContainer', { static: false }) public dataContainer: ElementRef;

  public html: any;

  constructor(private settingService: SettingService) {
  }

  public ngOnChanges() {
    let id: SettingType = 'Audience Agreement (English)';
    if (this.language) {
      if (this.language === 'Spanish') {
        id = 'Audience Agreement (Spanish)';
      }
      this.settingService.getById(id).subscribe(
        (agreement) => {
          DOMPurify.addHook('afterSanitizeAttributes', function (node) {
            if ('target' in node) {
              node.setAttribute('target', '_blank');
              node.setAttribute('rel', 'noopener');
            }
          });
          this.dataContainer.nativeElement.innerHTML = DOMPurify.sanitize(agreement.value);
        }
      );
    }
  }
}
