import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import { ScreeningInvitationService, SharedService } from '../../../services';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class PrivateInviteGuard implements CanActivate {
  constructor(private invitationService: ScreeningInvitationService, private router: Router,
    private sharedService: SharedService, private cookieService: CookieService) { }

  public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    let isAdmin;
    // Admins are always allowed
    let roles = this.cookieService.get('roles');
    if (roles) {
      let arr: Array<string> = JSON.parse(roles);
      arr.forEach((role: any) => {
        isAdmin = ['Administrator', 'SuperAdmin', 'Manager', 'Limited'].includes(role.name);
      });
    }
    // tslint:disable
    const screeningNumber = route.params['screeningNumber']
    const id = route.params['invitationId'] || route.params['id'];
    const hasRegistration = route.data['hasRegistration']
    return this.invitationService.getById(id, true).pipe(take(1)).toPromise()
      .then((invite) => {
        if (isAdmin) {
          return Promise.resolve(true);
        }
        const isConfirmationUrl = !!screeningNumber;
        const screening = screeningNumber === 2 ? invite.Screening2 : invite.Screening1;
        // tslint:enable
        let isClosedOrCanceled = this.getClosedOrCanceled(screening);
        let isInvited = this.getIsInvited(screening);

        if (!isConfirmationUrl && invite.Screening2) {
          const screening2 = invite.Screening2;
          // check both screenings if going to invitation page (can still see invite if just one screening is closed)
          isClosedOrCanceled = isClosedOrCanceled && this.getClosedOrCanceled(screening2);
          isInvited = this.getIsInvited(screening);
        }

        if (invite.isPublic && isClosedOrCanceled) {
          this.setError();
          this.router.navigate(['registration-error']);
          return Promise.resolve(false);
        }
        if (!invite.isPublic || (invite.isLimited && !hasRegistration) || invite.isDeleted || !isInvited) {
          this.router.navigate(['']);
          return Promise.resolve(false);
        }
        return Promise.resolve(true);
      })
      .catch((err) => {
        this.router.navigate(['']);
        return Promise.resolve(false);
      });

  }

  private getClosedOrCanceled(screening: any) {
    return screening.status === '5' || screening.status === '4';
  };
  private getIsInvited(screening: any) {
    return screening.status === '3';
  }
  private setError() {
    this.sharedService.setRegistrationError({
      errorTitle: 'Screening Unavailable',
      pageTitle: 'Screening Unavailable',
      message: 'Sorry, registration for this screening is not available.'
    });
  }
}
