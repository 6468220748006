import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Registration } from '../../../../../../common/interfaces';
import { AccountExpanded } from '../../../../../../common/interfaces/account';
import { ScreeningDateFilterType } from '../../../../../../common/interfaces/screening';
import { AccountService } from '../../../../services/account.service';

@Component({
  selector: 'user-screenings-page',
  styleUrls: ['./user-screenings-page.component.scss'],
  templateUrl: './user-screenings-page.component.pug'
})
export class UserScreeningsPage implements OnInit {
  public data: Registration[];
  public isUpcoming: boolean = true;
  private account: AccountExpanded;

  constructor(private titleService: Title, private accountService: AccountService) {
    this.accountService.currentUser.subscribe(
      currentUser => {
        this.account = currentUser;
        if (currentUser) {
          this.getScreenings(this.isUpcoming);
        }
      }
    );
  }

  public ngOnInit() {
    this.titleService.setTitle('My Screenings');
  }

  public toggleList() {
    this.isUpcoming = !this.isUpcoming;
    this.getScreenings(this.isUpcoming);
  }

  private getScreenings = (upcoming: boolean) => {
    if (!this.account) {
      return;
    }
    let filter = upcoming ? ScreeningDateFilterType.Upcoming : ScreeningDateFilterType.Past;
    console.log(filter)
    this.accountService.getScreeningRegistrations(filter).subscribe(
      registrations => {
        this.data = registrations;
      }
    );
  }
}
