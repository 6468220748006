import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'terms-conditions-page',
  styleUrls: ['./terms-conditions-page.component.scss'],
  templateUrl: './terms-conditions-page.component.pug'
})
export class TermsConditionsPage implements OnInit {
  public today: Date;
  constructor(private titleService: Title, private route: ActivatedRoute) { }

  public ngOnInit() {
    this.titleService.setTitle('Preview Free Movies - Terms and Conditions');
    this.today = new Date();
  }
}
