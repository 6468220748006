// Based heavily on: http://stackoverflow.com/a/36330518
import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import Clipboard from 'clipboard';

@Directive({
  selector: '[clipboard]'
})

export class ClipboardDirective {
  @Input('clipboard') public elt: Element;
  @Output() public clipboardSuccess: EventEmitter<any> = new EventEmitter();
  @Output() public clipboardError: EventEmitter<any> = new EventEmitter();

  public clipboard: Clipboard;

  constructor(private eltRef: ElementRef) { }

  public ngOnInit() {
    this.clipboard = new Clipboard(this.eltRef.nativeElement, {
      target: () => {
        return this.elt;
      }
    });

    this.clipboard.on('success', (e) => {
      this.clipboardSuccess.emit();
    });

    this.clipboard.on('error', (e) => {
      this.clipboardError.emit();
    });
  }

  public ngOnDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }
}
