import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { HTTPMethod, NetworkService } from './network.service';

import { NDA } from '../../../common/interfaces';

@Injectable()
class NDAService {

  constructor(private networkService: NetworkService, private http: HttpClient) { }

  public getById(ndaId: number): Observable<NDA> {
    return this.networkService.httpRequest<NDA>(HTTPMethod.GET, `NDAs/${ndaId}`);
  }

  public getPDF(pdfUrl: string): Observable<any> {
    return this.http.get(pdfUrl, { responseType: 'arraybuffer' })
  }

}

export { NDAService };
