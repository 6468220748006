import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Answer, GuestType, Question, QuestionTemplateId } from '../../../../../../common/interfaces';
import { FieldValidator } from '../../../common/validator';

@Component({
  selector: 'kid-form',
  styleUrls: ['./kid-form.component.scss'],
  templateUrl: './kid-form.component.pug'
})
export class KidForm implements OnInit {
  @Input() public guestNumber: number;
  @Input() public guestLabel: string;
  @Input() public isFamilyMode: boolean;
  @Input() public isChildren: boolean;
  @Input() public guest: any;
  @Input() public child: any;
  @Input() public questions: Array<Question>;
  @Input() public isEditing = false;
  @Input() public countryId: number;
  @Output() public guestValidationStateChanged = new EventEmitter<boolean>();
  public form: FormGroup;
  public guests: Array<any>;
  public options: Array<any>;
  public firstNameControl = new FormControl('', FieldValidator.validateName);
  public lastNameControl = new FormControl('', FieldValidator.validateName);
  public phoneTypeControl = new FormControl();
  public guestQuestions: Array<Question>;
  private isValid: boolean;
  public formControlSuffix: string;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) { }

  public ngOnInit() {
    // console.log('guest form init')
    // console.log(this.guest)
    // console.log(this.questions)
    this.guestQuestions = this.questions.map(x => structuredClone(x));
    this.form = this.formBuilder.group({});
    let guestType = this.isFamilyMode ? 'Child ' : 'Guest ';
    if (this.isChildren) {
      guestType = 'Child ';
    }
    this.guestLabel = guestType + this.guestNumber + ' ';
    if (!this.guest) { this.guest = {}; }
    this.formControlSuffix = `${this.guestNumber}${this.isFamilyMode}`;
    this.setUpForm();
    // this.guestQuestions = this.questions.map(x => structuredClone(x));
    // if(!this.questions) {
    //   let questions = [
    //     {
    //       displayOrder: 0,
    //       displayQuestion: 'Age',
    //       fieldCode: 'Age',
    //       formControlName: '304711false',
    //       id: 30471,
    //       label: 'Age',
    //       questionTemplateId: 1,
    //       configJSON: {
    //         answers: ["5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17"],
    //         dataType: 358,
    //         display: "Always"
    //       }
    //     }, {
    //       displayOrder: 2,
    //       displayQuestion: 'Ethnicity',
    //       fieldCode: 'Ethnicity',
    //       formControlName: '304731false',
    //       id: 30473,
    //       label: 'Ethnicity',
    //       questionTemplateId: 2,
    //       configJSON: {
    //         answers: ["African-American/Black", "Asian", "Caucasian/White", "Hispanic/Latino", "Some Other Race"],
    //         dataType: 358,
    //         display: "Always"
    //       }
    //     }, {
    //       displayOrder: 3,
    //       displayQuestion: 'Gender',
    //       fieldCode: 'Gender',
    //       formControlName: '304751false',
    //       id: 30475,
    //       label: 'Gender',
    //       questionTemplateId: 3,
    //       configJSON: {
    //         answers: ["Male", "Female"],
    //         dataType: 358,
    //         display: "Always"
    //       }
    //     }
    //   ];
    //   this.guestQuestions = questions;
    // }
  }

  // public ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
  //   // if (this.questions && this.questions.length > 0) { return; }
  //   // tslint:disable-next-line
  //   for (let propName in changes) {
  //     let changedProp = changes[propName];
  //     if (propName === 'guest') {
  //       console.log('guest changed')
  //       console.log(this.guest)
  //     }
  //     if (propName === 'questions') {
  //       console.log('questions changed')
  //       console.log(this.questions)
  //     }
  //   }
  // }

  public valid() {
    return this.isValid;
  }

  public values() {
    let guest: any = this.guest;
    guest.Answers = guest.Answer ? guest.Answer : [];
    // if(this.isFamilyMode) {
    let type: GuestType = this.isFamilyMode ? 'Child' : 'Guest';
    guest.type = type;
    // }
    if (this.guestQuestions) {
      this.guestQuestions.forEach(question => {
        let control = this.form.controls[question.formControlName];
        if (!control.pristine) {
          // get answer data
          let answer: Answer = {
            questionId: question.id,
            questionTemplateId: question.questionTemplateId,
            answerJSON: { values: [control.value], label: question.label }
          };
          guest.Answers.push(answer);

          // update gender/ethnicity/age properties
          if (question.questionTemplateId === QuestionTemplateId.Ethnicity || question.questionTemplateId === QuestionTemplateId.ChildEthnicity) {
            guest.ethnicity = control.value;
          } else if (question.questionTemplateId === QuestionTemplateId.Gender || question.questionTemplateId === QuestionTemplateId.ChildGender) {
            guest.gender = control.value;
          } else if (question.questionTemplateId === QuestionTemplateId.Age ||
            question.questionTemplateId === QuestionTemplateId.ChildAge) {
            guest.ageGroup = control.value;
          }
        }
      });
    }
    return guest;
  }

  // show/hide askIf questions based on parent answer value
  public onAskIfChanged($event) {
    let parentId = $event.questionId;
    let answer = $event.answer;
    let dataType = $event.dataType;
    let shouldShow;
    this.guestQuestions.forEach((linkedQuestion) => {
      let config = linkedQuestion.configJSON;
      if (config.askIfId === parentId) {
        switch (config.askIfOperator) {
          case 'IS':
            if (dataType !== 1) {
              shouldShow = answer === config.askIfValue;
            } else {
              shouldShow = answer.toLowerCase() === config.askIfValue.toLowerCase();
            }
            break;
          case 'ISN\'T':
            if (dataType !== 1) {
              shouldShow = answer !== config.askIfValue;
            } else {
              shouldShow = answer.toLowerCase() !== config.askIfValue.toLowerCase();
            }
            break;
          case 'INCLUDES':
          case 'EXCLUDES':
            if (dataType !== 1) {
              shouldShow = answer[config.askIfValue] === !!(config.askIfOperator === 'INCLUDES');
            } else {
              let found = answer.toLowerCase().search(config.askIfValue.toLowerCase()) > -1;
              shouldShow = (found && config.askIfOperator === 'INCLUDES')
                || (!found && config.askIfOperator !== 'INCLUDES');
            }
            break;
          case 'COUNT GREATER THAN':
          case 'COUNT LESS THAN':
            let values = answer.filter((value) => {
              return value === true;
            });
            if (config.askIfOperator === 'COUNT GREATER THAN') {
              shouldShow = values.length > config.askIfValue;
            } else {
              shouldShow = values.length < config.askIfValue;
            }
          default:
            break;
        }
        if (!this.form.controls[linkedQuestion.formControlName]) { return; }
        if (linkedQuestion.isRequired) {
          let validators = shouldShow ? Validators.required : null;
          this.form.controls[linkedQuestion.formControlName].setValidators(validators);
        }
        linkedQuestion.hidden = !shouldShow;
      }
    });
  }

  private setUpForm() {
    let group: any = {};
    let guest: any = this.guest;
    let guestAnswers = {};
    if (guest && Object.keys(guest).length) {
      if (Array.isArray(guest.Answer)) {
        guest.Answer.forEach((answer) => {
          if (answer.answerJSON && answer.answerJSON.values[0]) {
            guestAnswers[answer.Question.id] = answer.answerJSON.values[0]
          }
        })
      }
    }
    // this.guestQuestions = guest.Answer.map(x => x.Question);
    // }
    // } else if (this.questions && !guest) {
    this.guestQuestions.forEach(question => {
      // set empty answer for freeform text questions to prevent 'undefined' in textarea
      let q: any = question;
      if (q.configJSON.dataType === 1) {
        q.answer = q.answer || '';
      }

      if (guestAnswers[q.id]) {
        q.answer = guestAnswers[q.id]
      }

      const formControlName = `${q.id}${this.formControlSuffix}`;
      question.formControlName = formControlName;
      if (!q.isRequired || q.hidden || this.isEditing) {
        group[formControlName] = new FormControl();
      } else {
        group[formControlName] = new FormControl(null, Validators.required);
      }
    });
    // }
    group[`firstName${this.formControlSuffix}`] = this.firstNameControl;
    group[`lastName${this.formControlSuffix}`] = this.lastNameControl;
    group[`email${this.formControlSuffix}`] = new FormControl(this.guest.email, FieldValidator.emailFormat);
    group[`phoneNumber${this.formControlSuffix}`] = new FormControl(this.guest.email, FieldValidator.validatePhoneNumber);
    group[`phoneType${this.formControlSuffix}`] = this.phoneTypeControl;
    this.form = this.formBuilder.group(group);
    this.form.valueChanges.subscribe(data => this.checkValidationState());
    // console.log('guest questions set')
    // console.log(this.guestQuestions)
    // console.log(group)
  }

  private checkValidationState() {
    if (!this.isValid) {
      this.isValid = true;
      this.guestValidationStateChanged.emit();
    }
    let valid = !this.form || this.form.valid;
    if (this.isValid === undefined || valid !== this.isValid) {
      this.isValid = valid;
      this.guestValidationStateChanged.emit();
    }
  }
}
